import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { SaveUserProfile, getUserDetails, getRoleDetails, websitevisitorsconverted } from '../../common/API'
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Select, MenuItem } from "@mui/material";
import Cookies from 'universal-cookie';
import { getIsPrivate, demoUserId, getCurrentUserId, getUserAgent } from '../../common/Functions';
import Autocomplete from '@mui/material/Autocomplete';
import GoogleLoginPage from '../Login/GoogleLoginPage';
import { ClientJS } from 'clientjs';
import { isNil } from 'lodash';
import './SignUp.scss'


class SignUp extends Component {

    state = {
        loginEmail: '',
        validLoginEmail: true,
        receiveUpdatesChecked: false,
        selectedRole: 'Student',
        disableRole: false,
        disableEmail: false,
        preFilledEmail: '',
        institute: '',
        userClass: '',
        isPrivate: true,
        currentcourses: 0,
        selectedcourse: null,
        useruniqueid: null,
        isSummitUser: false,
        teacherEmail: '',
        validTeacherEmail: false,
        password: '',
        loginid: '',
        hideemailfield: false,
        objcurrentcourses: ['Physics', 'Chemistry', 'Calculus', 'Statistics'],
        isOpen: false,
        tEmailUrl: '',
        copymesssage: `Dear Students, 
                        I am happy to provide my students Free access to this AI-powered test-prep tool (including 5 practice 
                        tests) for the upcoming May 17
                        th AP Physics exam. To get free access, "https://aiplato.ai/signup" and enter my email 
                        in the field “Teacher’s Email”. 
                        Here is a short "https://docsend.com/view/cg8fsggqi3dxxjgu" demo of this practice test feature. It is from "https://aiplato.ai", an AI Tutor for STEM trusted 
                        by top universities like Harvard, Rice, and NYU. It is developed by Harvard/Stanford educators and ex-Google/Meta AI/ML experts. 
                        5 Practice Tests with Personalized Test-prep - AP Physics 1
                            ✓ Take AP Practice Test in Exam Mode, and get immediate assessment on AP Practice Tests, with 
                            detailed proficiency-map and resources to swiftly address knowledge gaps. 
                            ✓ Try AP Practice Test in Guided Mode, and get immediate, precise, and personalized step-by-step feedback, 
                            and learn to solve complex problems. 
                        Teacher Tools
                            ✓ After the practice test, students can choose to share with teachers their practice test progress 
                            and detailed proficiency map, to enable teachers to help them better`
    }

    componentWillMount() {
        this.setState({ isPrivate: getIsPrivate() })
        if (!isNil(window.location.search) && window.location.search !== '') {
            const queryString = window.atob(window.location.search.slice(1));
            const queryParamsArray = queryString.split('&');
            let tempObj = {}
            queryParamsArray.forEach(param => {
                const [key, value] = param.split('=');
                if (key === 'summit' && value === 'true') {
                    this.setState({ isSummitUser: true });
                }
                if (key === 'temail') {
                    this.setState({ teacherEmail: value === 'teacher@fusd.org' ? 'FreeAccessTeacher@aiPlato.ai' : value });
                    this.setState({ validTeacherEmail: this.validateEmail(value) });
                }
            });
        }
    }

    componentDidMount() {
    
        var userUniqueId = this.props.match.params.useruniqueid;
        if (userUniqueId !== undefined && userUniqueId !== null && userUniqueId !== "") {
            this.setState({ useruniqueid: userUniqueId })
        }


        const queryParameters = new URLSearchParams(window.atob(window.location.search.substring(1)));

        const email = queryParameters.get("email")
        if (email !== null) {
            const emailId = email
            this.setState({ preFilledEmail: emailId })

            getUserDetails(emailId.trim()).then(res => {
                this.setState({ selectedRole: res.data.role })

                getRoleDetails(res.data.professor_id).then(res1 => {

                    let json = res1.data
                    for (let count = 0; count < Object.keys(json.Institutes).length; count++) {
                        if (Object.values(json.Institutes)[count].institute_id.toString() === res.data.institute_id.toString()) {
                            this.setState({ institute: Object.keys(json.Institutes)[count].toString() })
                            for (let index = 0; index < Object.values(json.Institutes)[count].classes.length; index++) {
                                if (Object.values(json.Institutes)[count].classes[index].class_id.toString() === res.data.class_id.toString())
                                    this.setState({ userClass: Object.values(json.Institutes)[count].classes[index].name.toString() })
                            }
                        }
                    }
                })
            })

            this.setState({ loginEmail: emailId.trimEnd() })
            this.setState({ validLoginEmail: true })
            this.setState({ disableRole: true })
            this.setState({ disableEmail: true })
        }

        const searchparams = window.location.search
        if (searchparams !== undefined && searchparams !== null) {
            if (searchparams === "?c3VtbWl0PXRydWU=") {
                this.setState({ hideemailfield: true })
            }
            else {
                this.setState({ hideemailfield: false })
            }
        }
        else {
            this.setState({ hideemailfield: false })
        }


    }

    handleRoleChange = (e) => {
        this.setState({ selectedRole: e.target.value })
    }

    handleEmailChange = (event) => {
        const newEmail = event.target.value.trimEnd();
        this.setState({ loginEmail: newEmail });
        this.setState({ validLoginEmail: this.validateEmail(newEmail) });
    }

    handleTeacherEmailChange = (event) => {
        const newEmail = event.target.value.trimEnd();
        this.setState({ teacherEmail: newEmail });
        this.setState({ validTeacherEmail: this.validateEmail(newEmail) });
    }

    validateEmail = (input) => {
        if (input.length == 0) { return true }
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(input);
    }

    onselectcurrentcourses = (e, values) => {
        if (values.length > 0) {
            this.setState({ currentcourses: values[0], selectedcourse: values[0] })
        }

    }
    handleSubmit = (event) => {
        event.preventDefault();

        if (this.state.selectedRole === "0") {
            toast.error("Please fill up all required fields !", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
        else {
            const form_data = new FormData(event.currentTarget);
            form_data.append("Username", form_data.get('firstName') + " " + form_data.get('lastName'))
            form_data.append("course", this.state.selectedcourse)
            form_data.append("useruniquecode", this.state.useruniqueid === null ? "" : this.state.useruniqueid)
            form_data.append('referURL', document.referrer);
            form_data.append('userAgent', getUserAgent());
            form_data.append("signupmethod", "aiPlato");
            form_data.append("summit", this.state.isSummitUser);
            // form_data.append("loginid", this.state.loginid);
            // form_data.append("temail", this.state.temail);
            form_data.delete("firstName")
            form_data.delete("lastName")


            SaveUserProfile(form_data).then(res => {
                const client = new ClientJS();
                const form_data1 = new FormData();
                const fingerprint = client.getFingerprint();
                form_data1.append("userId", fingerprint)
                form_data1.append('converted_from', "S");
                form_data1.append("userdata", this.state.loginEmail)
                websitevisitorsconverted(form_data1).then(res => {
                })

                if (res.data !== undefined && res.data.Success !== undefined && res.data.Success === "exist") {
                    toast.info("User already exists! Please Login to continue...", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                    setTimeout(() => {
                        window.location.href = '/login'
                        //window.location.reload(false)
                    }, 3000);
                }
                else if (res.data !== undefined && res.data.Success === "Not in Check Table") {
                    toast.info("Thanks for reaching out! We will get back to you soon!")
                }
                else {
                    toast.success("Sign Up successfully! Please Login to continue..", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                    if (this.state.isSummitUser) {
                        this.setState({ tEmailUrl: `https://aiplato.ai/signup?${window.btoa('temail=' + this.state.loginEmail)}` })
                        this.setState({ isOpen: true })
                    } else {
                        setTimeout(() => {
                            window.location.href = '/login'
                        }, 3000);
                    }

                }
                // setTimeout(() => {
                //     window.location.href = '/login'
                //     //window.location.reload(false)
                // }, 3000);
            }).catch(err => {
                toast.error("Error!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });

                console.error(err.message)
            })
        }
    }

    render() {

        return (
            this.state.isPrivate ?
                <div className='fwidth'>
                    <div className="banner-pt container-fluid bg-gray1 pb-3">
                        <Container className="gradientBannerBg">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className='homeBannerHead1'>
                                    <h1>STEM AI Tutor</h1> <h3>Loved by Students, Developed by Educators</h3>
                                </div>

                            </div>
                        </Container>
                    </div>
                    <div className="container-fluid px-0">
                        <Container className='pt-2 pt-md-5' >
                            <Box component="form" onSubmit={this.handleSubmit} sx={{ my: 1, mx: 'auto', width: ['100%', 500] }} className='whiteBG'>
                                <Typography component="h1" variant="h5" sx={{ pb: 2 }}>
                                    {this.state.isSummitUser ? 'Sign up for Teachers' : 'Sign up for Students'}
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"
                                            // required
                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            // required
                                            fullWidth
                                            id="lastName"
                                            label="Last Name"
                                            name="lastName"
                                            autoComplete="family-name"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="loginid"
                                            required
                                            fullWidth
                                            id="loginid"
                                            label="User Id"
                                            autoFocus
                                            onChange={(event) => this.setState({ loginid: event.target.value.trimEnd() })}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            required={this.state.isSummitUser ? true : false}
                                            fullWidth
                                            id="email"
                                            label={!this.state.isSummitUser ? "Email Address (Optional)" : "Email Address"}
                                            name="Emailid"
                                            autoComplete="email"
                                            value={this.state.loginEmail}
                                            disabled={this.state.disableEmail}
                                            onChange={this.handleEmailChange}
                                            // error={!this.state.validLoginEmail}
                                            helperText={!this.state.validLoginEmail ? 'Invalid email format' :'Highly recommended for support and password recovery'}
                                        />
                                    </Grid>


                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="Password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            autoComplete="new-password"
                                            onChange={(event) => this.setState({ password: event.target.value.trimEnd() })}
                                        />
                                    </Grid>

                                    {
                                        !this.state.hideemailfield ?
                                            (
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="temail"
                                                        label="Your Teacher's Email"
                                                        name="temail"
                                                        autoComplete="temail"
                                                        value={this.state.teacherEmail}
                                                        disabled={this.state.disableEmail}
                                                        onChange={this.handleTeacherEmailChange}
                                                        // error={!this.state.validLoginEmail}
                                                        helperText={!this.state.validTeacherEmail ? 'Invalid email format' : this.state.teacherEmail ==='FreeAccessTeacher@aiPlato.ai' ? 'You can use this prepopulated email for free access, if your teacher is not signed up.' : ''}
                                                    />
                                                </Grid>
                                            ) : null
                                    }
                                    <Grid item xs={12}>

                                        {/* <Selectb
                                            placeholder="STEM Subject of Interest"
                                            value={this.state.currentcourses} // set selected value
                                            options={this.state.objcurrentcourses} // set list of the data
                                            onChange={this.onselectcurrentcourses} // assign onChange function
                                            isOptionDisabled={(option) => option.isdisabled} // disable an option
                                        /> */}
                                        <Autocomplete
                                            multiple
                                            fullWidth
                                            onChange={this.onselectcurrentcourses}
                                            options={this.state.objcurrentcourses}
                                            getOptionDisabled={(option) => {
                                                return option === this.state.objcurrentcourses[1] || option === this.state.objcurrentcourses[2] || option === this.state.objcurrentcourses[3]
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} placeholder='STEM Subject of Interest'
                                                    variant="outlined" />
                                            )}
                                        />

                                        {/* <Select
                                            name="course"
                                            id="usercourse"
                                            fullWidth
                                            onChange={this.onselectcurrentcourses}
                                            select={this.state.currentcourses}
                                            value={this.state.currentcourses}
                                            defaultValue={0}
                                        >
                                            <MenuItem key={0} selected value="0"> Select Course </MenuItem>
                                            <MenuItem key={1} value="Physics">Physics</MenuItem>
                                            <MenuItem key={2} value="Chemistry" disabled={true}>Chemistry</MenuItem>
                                            <MenuItem key={3} value="Calculus" disabled={true}>Calculus</MenuItem>
                                            <MenuItem key={4} value="Statistics" disabled={true}>Statistics</MenuItem>
                                        </Select> */}
                                    </Grid>
                                    {this.state.disableRole || this.state.isSummitUser ?
                                        <Grid item xs={12}>
                                            <TextField

                                                fullWidth
                                                name="institute"
                                                label="Institute"
                                                id="institute"

                                            />
                                        </Grid> : null}

                                    {this.state.disableRole || this.state.isSummitUser ?
                                        <Grid item xs={12}>
                                            <TextField
                                                type='number'
                                                fullWidth
                                                name="noOfStudents"
                                                label="Number of Physics Students for Free Access: "
                                                id="students"

                                            />
                                        </Grid> : null}
                                    {this.state.disableRole ?
                                        <Grid item xs={12}>
                                            <TextField
                                                disabled
                                                fullWidth
                                                name="class"
                                                label="User Class"
                                                id="class"
                                                value={this.state.userClass}
                                            />
                                        </Grid> : null}
                                    {!this.state.isSummitUser ?
                                        <Grid item xs={12}>
                                            <Select
                                                name="role"
                                                id="userRole"
                                                required
                                                fullWidth
                                                onChange={this.handleRoleChange}
                                                select={this.state.selectedRole}
                                                value={this.state.selectedRole}
                                                disabled={this.state.disableRole}
                                                defaultValue={0}
                                            >
                                                <MenuItem key={0} selected value="0"> Select Role </MenuItem>
                                                <MenuItem key={1} value="Professor">Professor</MenuItem>
                                                <MenuItem key={2} value="TA">Assistant Teacher / Teaching Assistant</MenuItem>
                                                <MenuItem key={3} value="Student">Student</MenuItem>
                                            </Select>
                                        </Grid> : null
                                    }

                                </Grid>
                                {!this.state.isSummitUser ?
                                    this.state.loginid !== '' && this.state.password !== '' ? <Button className='btnMain' type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}> Sign Up </Button>
                                        : <Button className='btnMain' style={{ cursor: ' not-allowed' }} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}> Sign Up </Button> :

                                    this.state.loginid !== '' && this.state.password !== '' && this.state.validLoginEmail ? <Button className='btnMain' type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}> Sign Up </Button>
                                        : <Button className='btnMain' style={{ cursor: ' not-allowed' }} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}> Sign Up </Button>

                                }

                                <Grid container style={{ marginTop: '20px' }}>
                                </Grid>

                                {/* <div style={{ textAlign: 'center', marginBottom: '10px' }}>OR</div>
                                <GoogleLoginPage /> */}

                                <Grid container style={{ marginTop: '25px' }}>
                                </Grid>

                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Link to="/login" variant="body2">
                                            Already have an account? Login
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Container>
                    </div>
                    <Modal style={{
                        display: 'flex',
                        alignItems: 'center', justifyContent: 'center',

                    }}
                        open={this.state.isOpen}
                        onClose={() => this.setState({ isOpen: false })}
                        aria-describedby="modal-modal-description"
                    >
                        <>
                            <div className='infoModal' >
                                <div className='headerItemsContainer'>
                                    <p style={{ textAlign: "left" }}>Please share the following message with your students to grant them access.</p>
                                    <IconButton className='closeButton' onClick={() => this.setState({ isOpen: false })}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>

                                <hr></hr>
                                {/* <a href={this.state.tEmailUrl}>{this.state.tEmailUrl}</a><br/> */}
                                <div className='copymaindiv'>
                                    <p> Dear Students,  <br /></p>
                                    <p>I am happy to provide my students Free access to this AI-powered test-prep tool (including 5 practice <br />tests) for the upcoming May 17th AP Physics exam. To get free access, <span style={{ textDecoration: "underline", color: "#000" }}><a style={{ color: "#000" }} alt="Sign Up for AiPlato" href="https://aiplato.ai/signup">"https://aiplato.ai/signup"</a></span> <br />and enter my email in the field &#8220;Teacher&#8217;s Email&#8221;.   <br /></p>
                                    <p>Here is a short <span style={{ textDecoration: "underline", color: "#000" }}><a style={{ color: "#000" }} alt="Sign Up for AiPlato" href="https://docsend.com/view/cg8fsggqi3dxxjgu">"https://docsend.com/view/cg8fsggqi3dxxjgu"</a></span> demo of this practice test feature.
                                        <br />It is from <span style={{ textDecoration: "underline", color: "#000" }}><a style={{ color: "#000" }} alt="Sign Up for AiPlato" href="https://aiplato.ai">"https://aiplato.ai"</a></span>, an AI Tutor for STEM trusted by top universities like Harvard,  Rice, <br />and NYU. It is developed by Harvard/Stanford educators and ex-Google/Meta AI/ML experts.  <br /></p>
                                    <p><b>5 Practice Tests with Personalized Test-prep &#8211;  AP Physics 1 <br /></b></p>
                                    <p className='marginleft30'>&#10003; Take AP Practice Test in Exam Mode, and get immediate assessment on AP Practice Tests, with <br />detailed <b>proficiency-map</b> and resources to swiftly address knowledge gaps.   <br /></p>
                                    <p className='marginleft30'>&#10003; Try AP Practice Test in Guided Mode, and get immediate, precise, and personalized <b>step-by-<br />step</b> feedback, and learn to solve complex problems.  <br /></p>
                                    <p><b>Teacher Tools   <br /></b></p>
                                    <p className='marginleft30'>&#10003; After the practice test, students can choose to share with teachers their practice test progress <br />and detailed proficiency map, to enable teachers to help them better. <br /></p>
                                    <p></p>
                                    <p> </p>

                                </div>
                                <div className='copyContainer'>
                                    <div className='copyButtonContainer'>
                                        <Button className='btnMain' variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => { navigator.clipboard.writeText(this.state.copymesssage) }}>Copy Message </Button>
                                    </div>
                                </div>


                            </div>
                        </>

                    </Modal>
                </div >

                :

                null
        )
    }
}

export default withRouter(SignUp);