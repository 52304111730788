import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Container } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { checkCredentialsAPI, validIds, saveToDbAPI } from '../../common/API'
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import { APP_URL, WEBSITE_URL, User_Role, getIsPrivate, setCookies, showFullApp, getUserAgent, detectDevice, DESKTOP, MOBILE } from '../../common/Functions';
import { isNil, isEmpty } from 'lodash';
import './Login.scss';
import GoogleLoginPage from './GoogleLoginPage';
import infoIcon from '../../assets/images/info_icon.png'

const style = {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
    height: 'fit-content'
};

const style1 = {
    my: 1,
    mx: 'auto',
    width: ['100%', 500]
}

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loginEmail: '',
            validLoginEmail: false,
            password: '',
            redirectToQue: '',
            sessionId: '',
            isPrivate: false,
            showHeader: true
        }
    }

    componentWillMount() {
        this.setState({ isPrivate: getIsPrivate() })
    }

    componentDidMount() {
        this.getURLParams()
        if (this.props.showHeader !== undefined) {
            this.setState({ showHeader: this.props.showHeader })
        }
    }

    getURLParams = () => {
        const queryParameters = new URLSearchParams(window.location.search.substring(1));
        if (!isNil(queryParameters) && queryParameters.size > 0) {
            this.setState({ sessionId: queryParameters.get("sessionId"), redirectToQue: queryParameters.get('fromQue').toString() });
        }

    }
    validateEmail = (input) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(input);
    }

    handleEmailChange = (event) => {
        const newEmail = event.target.value;
        this.setState({ loginEmail: newEmail });
        this.setState({ validLoginEmail: this.validateEmail(newEmail) });
    }

    getEarlyAccessHandler = (e) => {
        e.preventDefault()
        if (this.state.password.length > 0) {
            let data = { 'email': this.state.loginEmail.trim().toLowerCase(), password: this.state.password, signupmethod: "aiPlato" }

            //   window.location.href = "/createclass"
            checkCredentialsAPI(data).then(res => {
                if (res.status === 200) {
                    debugger;
                    if (res.data['found'] === true && res.data.status === "Active") {
                        this.addValidationCookie(res.data.email.trim().toLowerCase(),
                            res.data.userId,
                            res.data.role,
                            res.data.name,
                            res.data.status,
                            res.data.tryThisPinsEnabled,
                            res.data.QATestFlag,
                            this.convertDurationToSeonds(res.data.timeLimit),
                            res.data.institute_id,
                            res.data.user_timezone,
                            res.data.isDemoUser,
                            res.data.isPtTeacher)
                        toast.success("Valid Demo Credentials!", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        if (this.state.redirectToQue === 'true') {
                            window.location.href = `/question/?sessionId=${this.state.sessionId}`
                        }
                        else if (this.state.loginEmail.trim().toLowerCase().includes("@rice.edu")) {
                            window.open(APP_URL + "assignments", '_self')
                            
                        } else {
                            if(showFullApp()){
                                window.open(APP_URL + "curriculum-problems", '_self')
                            }else {
                                window.open(APP_URL, '_self')
                            }
                            
                            this.setState({ email: '', password: '', showPassword: false });
                            this.setState({ shoowearlyaccess: true, showemaildiv: false, showdropdown: true, studenttutorvalue: "", buttontext: "Request Access" })
                        }

                    }
                    else if (res.data['isexpired'] === true) {
                        toast.error("Your account is expired, please get in touch with support team!", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        this.setState({ shoowearlyaccess: true, showemaildiv: false })
                    }
                    else {
                        toast.error("Please enter valid email id and password.", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        this.setState({ shoowearlyaccess: false, showemaildiv: true })
                    }
                } else {
                    toast.error("Server Error !", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            }).catch(err => {
                console.error(err.message)
                toast.error("Please enter valid email id and password.", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
        }
        /*  else {
              // save email to db
              let data = { 'email': this.state.loginEmail.trim().toLowerCase(), studenteducatior: this.state.studenttutorvalue == "" ? "Educator" : this.state.studenttutorvalue }
              saveToDbAPI(data).then(res => {
                  toast.success("Thank you for submitting your early access request. Our team will be in touch with you shortly to provide further information and support.", {
                      position: toast.POSITION.BOTTOM_RIGHT
                  });
                  // this.setState({ email: '' });
                  // this.setState({ shoowearlyaccess: true, showemaildiv: false })
                  window.open(`https://aiplato.ai/earlyaccesspriorityinfo/${res.data.data}`)
              }).catch(err => {
                  console.error(err.message)
                  toast.warning("We already have your email in our system, someone from our team contact you soon.", {
                      position: toast.POSITION.BOTTOM_RIGHT
                  });
                  this.setState({ email: '' });
              })
          } */
    }

    addValidationCookie = (email, userId, role, name, status, tryThisPinsEnabled, QATestFlag, timeLimit, institute_id, usertimezone, isDemoUserFlagged, isPtTeacher) => {
        setCookies(email, userId, role, name, status, tryThisPinsEnabled, QATestFlag, timeLimit, institute_id, usertimezone, "", false, isDemoUserFlagged, isPtTeacher)
    }

    convertDurationToSeonds = (duration) => {
        const a = duration.split(':');
        return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    }

    render() {
        
        return (
            this.state.isPrivate ?
              
                    <div className='fwidth'>
                        {this.state.showHeader ?
                            <div className="banner-pt container-fluid bg-gray1 pb-3">
                                <Container className="gradientBannerBg">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className='homeBannerHead1'>
                                            <h1>STEM AI Tutor</h1> <h3>Loved by Students, Developed by Educators</h3>
                                        </div>
                                    </div>
                                </Container>
                            </div>
                            : null
                        }
                        <div className="container-fluid px-0">
                            <Container className='pt-2 pt-md-5'  >
                                <Box component="form" onSubmit={this.getEarlyAccessHandler} sx={this.state.showHeader ? style1 : style} className='whiteBG '>
                                    {this.state.showHeader ?
                                        <Typography component="h1" variant="h5">
                                            Login
                                        </Typography>
                                        : null
                                    }

                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address/User Id"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                        value={this.state.loginEmail}
                                        onChange={this.handleEmailChange}
                                    // error={!this.state.validLoginEmail}
                                    // helperText={!this.state.validLoginEmail ? 'Invalid email format' : ''}

                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        value={this.state.password}
                                        onChange={(e) => this.setState({ password: e.target.value })}
                                        autoComplete="current-password"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox value="remember" color="primary" />}
                                        label="Remember me"
                                    />
                                    {this.state.loginEmail !== '' ? <Button className='btnmain' type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}> Sign In </Button>
                                        : <Button className='btnmain' style={{ cursor: ' not-allowed' }} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}> Sign In </Button>}

                                    <div style={{ textAlign: 'center', marginBottom: '10px' }}>OR</div>

                                    {/* <GoogleLoginPage></GoogleLoginPage> */}

                                    <Grid container>
                                        {/* <Grid item xs>
                                        <Link href="#" variant="body2">
                                            Forgot password?bt
                                        </Link>
                                    </Grid>  */}
                                        {this.state.showHeader ?
                                            <Grid item style={{ marginTop: '10px' }}>
                                                <Link target="_self" to="/signup" variant="body2">
                                                    {"Don't have an account? Sign Up"}
                                                </Link>
                                            </Grid>
                                            :
                                            <Grid item style={{ marginTop: '10px' }}>
                                                <a href={WEBSITE_URL + "signup"}>
                                                    {"Don't have an account? Sign Up"}
                                                </a>
                                            </Grid>
                                        }
                                    </Grid>

                                </Box>
                            </Container>
                        </div>
                    </div>

                :
                null
        )
    }
}

export default withRouter(Login);