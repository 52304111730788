import React, { Component } from 'react';
import { Row, Col, Container } from "react-bootstrap"
import './contactUs.scss';
import Cookies from 'universal-cookie';
import { getIsPrivate } from '../../common/Functions';

class ContactUs extends Component {

    state = {
        isPrivate: false,
    }

    componentWillMount() {
        this.setState({ isPrivate: getIsPrivate() })
    }

    render() {
        window.scroll(0, 0)
        return (
            this.state.isPrivate ?
                <div className='fwidth'>
                    <div className="banner-pt container-fluid bg-gray1 pb-5">
                        <Container className="gradientBannerBg ">
                            <div className="col-12 col-md-12 col-lg-12">
                            <div className='homeBannerHead1'>
                                    <h1>STEM AI Tutor</h1> <h3>Loved by Students, Developed by Educators</h3>
                                </div>
                                <div className="mt-4 text-center"><a href="mailto:info@aiplato.ai" target='blank' className='btnMain'>Contact Us</a></div>
                            </div>
                        </Container>
                    </div>
                    <div className="container-fluid">
                        <Container className='pt-5' style={{ height: "100vh" }} >

                        </Container>
                    </div>
                </div>
                :
                <div className='fwidth'>
                    <div className="homecontmpad container-fluid">
                        <Container className="">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className='homeBannerHead'><h1 className='text-white'>A Stealth Mode Startup</h1></div>
                                <p className='text-white pt-2 bannerText' style={{ margin: '0' }}>Democratizing 1-on-1 Teaching.</p>
                                <p className='text-white bannerText'>Reinventing STEM Education.</p>
                                <div className="mt-4"><a href="mailto:info@aiplato.ai" target='blank' className='btn btn-white'>Contact Us</a></div>
                            </div>
                        </Container>
                    </div>
                    <div className="container-fluid">
                        <Container className='pt-5' style={{ height: "100vh" }} >

                        </Container>
                    </div>
                </div>

        )
    }
}

export default ContactUs;