import React from 'react';
import { Row, Col, Container } from "react-bootstrap"
import Hyperlink from '../shared/Hyperlink/Hyperlink'
import logoImg from '../../assets/images/logo_aiPlato-white-h.png'
import { Link } from 'react-router-dom';

import './Footer.scss';
class Footer extends React.Component {
    render() {
        return <div className='footerContainer'>
            <Container>
                <Row className='pt-3'>
                    <Col xs={12} md={6} className='pb-2 copyrightText'>
                        <a alt="aiPlato" href="/" ><img src={logoImg} alt="aiPlato"></img></a>
                        <p>Copyright @{new Date().getFullYear()} aiPlato, Inc.</p>
                        <p>All rights reserved</p>
                    </Col>
                    <Col xs={12} md={3} className='pb-2'>

                    </Col>
                    <Col xs={12} md={3} className='pb-2'>
                        <h5>Company</h5>
                        <div className='companyLink'>
                            <a className='text-white' href="mailto:support@aiplato.ai" target='blank' >Support</a>
                            <Link type="Link_Primary" className="Blacknavtext" to="/team">About Us</Link>
                            <Link type="Link_Primary" className="Blacknavtext" to="/contact">Contact Us</Link>
                        </div>

                    </Col>
                </Row>

            </Container>




        </div>
    }
}

export default Footer