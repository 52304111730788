import React from 'react';
import './PrivacyPolicy.scss';
import aiplatologo from '../../assets/images/aiplatoplogo.png';
import { Row, Col, Container } from "react-bootstrap"


const PrivacyPolicy = () => {
    return (
        <>
            <div className='fwidth' style={{paddingLeft:"15px", paddingRight:"15px"}}>
                <Container className="secpad containerpp">
                    <div>
                        <div>
                            <p style={{ textAlign: "center" }}>
                                <img src={aiplatologo} width="67" height="53" alt="" />
                            </p>
                        </div>
                        <div>
                            <h1 style={{ textAlign: "center" }}>
                                <span className="bumpedfont15">
                                    aiPlato Privacy Policy </span></h1>
                        </div>
                        <div>
                        </div>
                        <div>
                            <p className="s5">
                                <span className="bumpedfont15normalItalic">
                                    Effective date: April 2, 2024 </span>
                                <span className="apple-converted-space">&#xa0;</span>

                            </p>
                        </div>
                        <div>
                        </div>
                        <p className="s5">
                            <span className="bumpedfont15normalItalic">
                                At aiPlato, we take your privacy seriously. Please read this Privacy Policy to learn how we treat your personal data. </span>
                            <span className="bumpedfont12italic">By using or accessing our Services in any manner, you acknowledge that you accept the practices and policies outlined below, and you hereby consent that we will collect, use and
                                share your information as described in this Privacy Policy. </span>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <span className="bumpedfont15normalItalic">
                                Remember that your use of aiPlato’s Services is at all times subject to our Terms of Use, which incorporates this Privacy Policy.
                                Any terms we use in this Policy without defining them have the definitions given to them in the <a className='underlinec' target="_blank" href='/termsandcondition'> Terms of Use</a>.
                            </span>

                        </p>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>

                        <p className="s5 ptag">
                            <span className="bumpedfont12italic" style={{ textDecoration: "underline" }}>
                                Privacy Policy Table of Contents </span></p>

                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont12italic underlinec">
                                    What this Privacy Policy Covers Personal Data</span>
                            </p>
                        </div>
                        <div>
                            <ul className='marginleft20'>
                                <li>
                                    <p className="s5 ptag">
                                        <span className="bumpedfont12italic underlinec">
                                            Categories of Personal Data We Collect</span>
                                    </p>
                                </li>
                                <li>
                                    <p className="s5 ptag">
                                        <span className="bumpedfont12italic underlinec">
                                            Categories of Sources of Personal Data</span>
                                    </p>
                                </li>
                                <li>
                                    <p className="s5 ptag">
                                        <span className="bumpedfont12italic underlinec">
                                            Our Commercial or Business Purposes for Collecting Personal
                                            Data</span>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont12italic underlinec">
                                    How We Disclose Your Personal Data</span>
                            </p>
                        </div>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont12italic underlinec">
                                    Tracking Tools and Opt-Out</span>
                            </p>
                        </div>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont12italic underlinec">
                                    Data Security and Retention</span>
                            </p>
                        </div>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont12italic underlinec">
                                    Personal Data of Children</span>
                            </p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont12italic underlinec">
                                    California Resident Rights</span>
                            </p>
                        </div>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont12italic">
                                    Other State Law Privacy Rights</span>
                            </p>
                        </div>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont12italic underlinec">
                                    Changes to this Privacy Policy</span>
                            </p>
                        </div>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont12italic underlinec">
                                    Contact Information</span>
                            </p>
                        </div>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont12">
                                    What this Privacy Policy Covers</span>
                            </p>
                        </div>

                        <div>

                            <p className="s5 ptag">
                                <span className="bumpedfont15normalItalic">
                                    This Privacy Policy covers how we treat Personal Data that we gather
                                    when you access or use our Services. “Personal Data” means any
                                    information that identifies or relates to a particular individual
                                    and also includes information referred to as “personally
                                    identifiable information” or “personal information” under applicable
                                    data privacy laws, rules or regulations. This Privacy Policy does
                                    not cover the practices of companies we don’t own or control or
                                    people we don’t manage.
                                </span>
                            </p>

                        </div>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont12">
                                    Personal Data</span>
                            </p>
                        </div>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont12italic">
                                    Categories of Personal Data We Collect</span>
                            </p>
                        </div>
                        <div>
                            <p className="s5">
                                <span className="bumpedfont15normalItalic">
                                    Following are the details the categories of Personal Data that we
                                    collect and have collected over the past 12 months:</span>
                            </p>
                        </div>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont12italic">
                                    Categories of Sources of Personal Data</span>
                            </p>
                        </div>
                        <div>
                            <p className="s5">
                                <span className="bumpedfont15normalItalic">
                                    We collect Personal Data about you from the following categories of
                                    sources:</span>
                            </p>
                        </div>
                        <div>
                            <ul className='marginleft20'>
                                <li>
                                    <p className="s5 ptag">
                                        <span className="bumpedfont12italic">
                                            You</span>
                                    </p>
                                </li>
                                <li className='marginleft20'>
                                    <p className="s5 ptag">
                                        <span className="bumpedfont12italic underlinec">
                                            When you provide such information directly to us.</span>
                                    </p>

                                    <div className='marginleft20'>
                                        <ol start="1">
                                            <li>
                                                <p className="s5 ptag">
                                                    <span className="bumpedfont15normalItalic">
                                                        When you create an account or use our interactive tools and
                                                        Services.</span>
                                                </p>
                                            </li>
                                        </ol>
                                        <ol start="2">
                                            <li>
                                                <p className="s5 ptag">
                                                    <span className="bumpedfont15normalItalic">
                                                        When you voluntarily provide information in free-form text
                                                        boxes through the Services or through responses to surveys
                                                        or questionnaires.</span>
                                                </p>
                                            </li>
                                        </ol>
                                        <ol start="3">
                                            <li>
                                                <p className="s5 ptag">
                                                    <span className="bumpedfont15normalItalic">
                                                        When you send us an email or otherwise contact us.</span>
                                                </p>
                                            </li>
                                        </ol>
                                    </div>
                                </li>

                                <li className='marginleft20'>
                                    <p className="s5 ptag">
                                        <span className="bumpedfont12italic underlinec">
                                            When you use the Services and such information is collected
                                            automatically.</span>
                                    </p>
                                    <div className='marginleft20'>

                                        <ol start="1">
                                            <li>
                                                <p className="s5 ptag">
                                                    <span className="bumpedfont15normalItalic">
                                                        Through Cookies (defined in the “Tracking Tools and Opt-Out”
                                                        section below).</span>
                                                </p>
                                            </li>
                                        </ol>
                                        <ol start="2">
                                            <li>
                                                <p className="s5 ptag">
                                                    <span className="bumpedfont15normalItalic">
                                                        If you download our mobile application or use a
                                                        location-enabled browser, we may receive information about
                                                        your location and mobile device, as applicable.</span>
                                                </p>
                                            </li>
                                        </ol>
                                        <ol start="3">
                                            <li>
                                                <p className="s5 ptag">
                                                    <span className="bumpedfont15normalItalic">
                                                        If you download and install certain applications and
                                                        software, we make available, we may receive and collect
                                                        information transmitted from your computing device for the
                                                        purpose of providing you the relevant Services, such as
                                                        information regarding when you are logged on and available
                                                        to receive updates or alert notices.</span>
                                                </p>
                                            </li>
                                        </ol>
                                    </div>

                                </li>
                            </ul>
                        </div>

                    </div>
                    <div>

                        <div>
                            <ul className='marginleft20'>
                                <li>
                                    <p className="s5 ptag">
                                        <span className="bumpedfont12italic">
                                            Third Parties</span>
                                    </p>
                                </li>
                                <li className='marginleft20'>
                                    <p className="s5 ptag">
                                        <span className="bumpedfont12italic underlinec">
                                            Vendors </span>
                                    </p>

                                    <div className='marginleft20'>
                                        <ol start="1">
                                            <li>
                                                <p className="s5 ptag">
                                                    <span className="bumpedfont15normalItalic">
                                                        We may use analytics providers to analyze how you interact and engage with the Services, or third parties may help us provide you with customer support.</span>
                                                </p>
                                            </li>
                                        </ol>
                                        <ol start="2">
                                            <li>
                                                <p className="s5 ptag">
                                                    <span className="bumpedfont15normalItalic">
                                                        We do not use vendors to obtain information to generate leads and create user profiles. </span>
                                                </p>
                                            </li>
                                        </ol>

                                    </div>
                                </li>

                                <li className='marginleft20'>
                                    <p className="s5 ptag">
                                        <span className="bumpedfont12italic underlinec">
                                            Advertising Partners </span>
                                    </p>
                                    <div className='marginleft20'>

                                        <ol start="1">
                                            <li>
                                                <p className="s5 ptag">
                                                    <span className="bumpedfont15normalItalic">
                                                        We receive information about you from some of our vendors who assist us with marketing or promotional services related to how you interact with our websites, applications, products, Services, or communications.</span>
                                                </p>
                                            </li>
                                        </ol>
                                    </div>
                                </li>
                                <li className='marginleft20'>
                                    <p className="s5 ptag">
                                        <span className="bumpedfont12italic underlinec">
                                            Social Networks</span>
                                    </p>
                                    <div className='marginleft20'>

                                        <ol start="1">
                                            <li>
                                                <p className="s5 ptag">
                                                    <span className="bumpedfont15normalItalic">
                                                        If you provide your social network account credentials to us or otherwise sign in to the Services through a third-party site or service, some content and/or information in those accounts may be transmitted into your account with us.
                                                    </span>
                                                </p>
                                            </li>
                                        </ol>

                                    </div>

                                </li>
                            </ul>
                        </div>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont12italic">
                                    Our Commercial or Business Purposes for Collecting Personal Data</span>
                            </p>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <div className='marginleft20'>
                                <ol start="1">
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont12italic">
                                                Providing, Customizing and Improving the Services</span>
                                        </p>
                                    </li>
                                </ol>

                                <div className='marginleft20'>
                                    <ul className='marginleft20'>
                                        <li>
                                            <p className="s5 ptag">
                                                <span className="bumpedfont15normalItalic">
                                                    Creating and managing your account or other user profiles.</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p className="s5 ptag">
                                                <span className="bumpedfont15normalItalic">
                                                    Providing you with the products, services or information you
                                                    request.</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p className="s5 ptag">
                                                <span className="bumpedfont15normalItalic">
                                                    Meeting or fulfilling the reason you provided the
                                                    information to us.</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p className="s5 ptag">
                                                <span className="bumpedfont15normalItalic">
                                                    Providing support and assistance for the Services.</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p className="s5 ptag">
                                                <span className="bumpedfont15normalItalic">
                                                    Improving the Services, including testing, research,
                                                    internal analytics and product development.</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p className="s5 ptag">
                                                <span className="bumpedfont15normalItalic">
                                                    Personalizing the Services, website content and
                                                    communications based on your preferences.</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p className="s5 ptag">
                                                <span className="bumpedfont15normalItalic">
                                                    Doing fraud protection, security and debugging.</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p className="s5 ptag">
                                                <span className="bumpedfont15normalItalic">
                                                    Carrying out other business purposes stated when collecting
                                                    your Personal Data or as otherwise set forth in applicable
                                                    data privacy laws, such as the California Consumer Privacy
                                                    Act (the “CCPA”).</span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>

                            </div>

                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <div className='marginleft20'>
                                <ol start="2">
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont12italic">
                                                Marketing the Services</span>
                                        </p>
                                    </li>
                                </ol>
                                <div className='marginleft20'>
                                    <ul className='marginleft20'>
                                        <li>
                                            <p className="s5 ptag">
                                                <span className="bumpedfont15normalItalic">
                                                    Marketing and selling the Services. </span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <div className='marginleft20'>
                                <ol start="3">
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont12italic">
                                                Corresponding with You </span>
                                        </p>
                                    </li>
                                </ol>

                                <div className='marginleft20'>
                                    <ul className='marginleft20'>
                                        <li>
                                            <p className="s5 ptag">
                                                <span className="bumpedfont15normalItalic">
                                                    Responding to correspondence that we receive from you, contacting you when necessary or requested,
                                                    and sending you information about aiPlato or the Services.</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p className="s5 ptag">
                                                <span className="bumpedfont15normalItalic">
                                                    Sending emails and other communications according to your preferences or that display content that we think will interest you. </span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>



                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <div className='marginleft20'>
                                <ol start="4">
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont12italic">
                                                Meeting Legal Requirements and Enforcing Legal Terms </span>
                                        </p>
                                    </li>
                                </ol>
                                <div className='marginleft20'>
                                    <ul className='marginleft20'>
                                        <li>
                                            <p className="s5 ptag">
                                                <span className="bumpedfont15normalItalic">
                                                    Fulfilling our legal obligations under applicable law, regulation, court order or other legal processes, such as preventing, detecting and investigating security incidents and potentially illegal or prohibited activities. </span>
                                            </p>
                                        </li>
                                        <li>
                                            <p className="s5 ptag">
                                                <span className="bumpedfont15normalItalic">
                                                    Protecting the rights, property or safety of you, aiPlato or another party. </span>
                                            </p>
                                        </li>

                                        <li>
                                            <p className="s5 ptag">
                                                <span className="bumpedfont15normalItalic">
                                                    Enforcing any agreements with you. </span>
                                            </p>
                                        </li>
                                        <li>
                                            <p className="s5 ptag">
                                                <span className="bumpedfont15normalItalic">
                                                    Responding to claims that any posting or other content violates third-party rights. </span>
                                            </p>
                                        </li>
                                        <li>
                                            <p className="s5 ptag">
                                                <span className="bumpedfont15normalItalic">
                                                    Resolving disputes. </span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                    </div>


                    <div>
                        <p className="s5 ptag">
                            <span className="bumpedfont12">
                                How we Disclose Your Personal Data</span>
                        </p>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont15normalItalic">
                                We disclose your Personal Data to the categories of service
                                providers and other parties listed in this section. Depending on
                                state laws that may be applicable to you, some of these disclosures
                                may constitute a “sale” of your Personal Data. For more information,
                                please refer to the state-specific sections below.
                            </span>
                        </p>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <div className='marginleft20'>
                            <ol start="1">
                                <li>
                                    <p className="s5 ptag">
                                        <span className="bumpedfont12italic">
                                            Service Providers. These parties help us provide the Services or perform business functions on our behalf. They include: </span>
                                    </p>
                                </li>
                            </ol>

                            <div className='marginleft20'>
                                <ul className='marginleft20'>
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normalItalic">
                                                Hosting, technology and communication providers.</span>
                                        </p>
                                    </li>
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normalItalic">
                                                Security and fraud prevention consultants. </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normalItalic">
                                                Support and customer service vendors. </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normalItalic">
                                                Product fulfillment and delivery providers. </span>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <div className='marginleft20'>
                            <ol start="2">
                                <li>
                                    <p className="s5 ptag">
                                        <span className="bumpedfont12italic">
                                            Advertising Partners. These parties help us market our services and provide you with other offers that may be of interest to you. They include:</span>
                                    </p>
                                </li>
                            </ol>

                            <div className='marginleft20'>
                                <ul className='marginleft20'>
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normalItalic">
                                                We do not sell your data to any advertising partners, data brokers or Marketing providers.  </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normalItalic">
                                                Your data is not used for anything other than stated educational purposes.</span>
                                        </p>
                                    </li>

                                </ul>
                            </div>
                        </div>




                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <div className='marginleft20'>
                            <ol start="3">
                                <li>
                                    <p className="s5 ptag">
                                        <span className="bumpedfont12italic">
                                            Analytics Partners. These parties provide analytics on web traffic or usage of the Services. They include: </span>
                                    </p>
                                </li>
                            </ol>

                            <div className='marginleft20'>
                                <ul className='marginleft20'>
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normalItalic">
                                                Companies that track how users found or were referred to the Services. </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normalItalic">
                                                Companies that track how users interact with the Services. </span>
                                        </p>
                                    </li>

                                </ul>
                            </div>
                        </div>



                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <div className='marginleft20'>
                            <ol start="4">
                                <li>
                                    <p className="s5 ptag">
                                        <span className="bumpedfont12italic">
                                            Parties You Authorize, Access or Authenticate</span>
                                    </p>
                                </li>
                            </ol>

                            <div className='marginleft20'>
                                <ul className='marginleft20'>
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normalItalic">
                                                Third parties you access through the services.</span>
                                        </p>
                                    </li>
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normalItalic">
                                                Social media services.  </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normalItalic">
                                                Other users. </span>
                                        </p>
                                    </li>

                                </ul>
                            </div>
                        </div>


                    </div>


                    <div>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont12italic underlinec">
                                    Legal Obligations</span>
                            </p>
                        </div>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont15normalItalic">
                                    We may share any Personal Data that we collect with third parties in
                                    conjunction with any of the activities set forth under “Meeting
                                    Legal Requirements and Enforcing Legal Terms” in the “Our Commercial
                                    or Business Purposes for Collecting Personal Data” section above.</span>
                            </p>
                        </div>
                    </div>
                    <p className="s5 ptag">
                        <span >&#xa0;</span></p>
                    <div>

                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont12italic underlinec">
                                    Business Transfers</span>
                            </p>
                        </div>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>

                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont15normalItalic">
                                    All of your Personal Data that we collect may be transferred to a
                                    third party if we undergo a merger, acquisition, bankruptcy or other
                                    transaction in which that third party assumes control of our
                                    business (in whole or in part). Should one of these events occur, we
                                    will make reasonable efforts to notify you before your information
                                    becomes subject to different privacy and security policies and
                                    practices.</span>
                            </p>
                        </div>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont12italic underlinec">
                                    Data that is Not Personal Data</span>
                            </p>
                        </div>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont15normalItalic">
                                    We may create aggregated, de-identified or anonymized data from the
                                    Personal Data we collect, including by removing information that
                                    makes the data personally identifiable to a particular user. We may
                                    use such aggregated, de-identified or anonymized data and share it
                                    with third parties for our lawful business purposes, including to
                                    analyze, build and improve the Services and promote our business,
                                    provided that we will not share such data in a manner that could
                                    identify you.</span>
                            </p>
                        </div>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont12">
                                    Tracking Tools and Opt-Out</span>
                            </p>
                        </div>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont15normalItalic">
                                    The Services use cookies and similar technologies such as pixel
                                    tags, web beacons, clear GIFs and JavaScript (collectively,
                                    “Cookies”) to enable our servers to recognize your web browser, tell
                                    us how and when you visit and use our Services, analyze trends,
                                    learn about our user base and operate and improve our Services.
                                    Cookies are small pieces of data– usually text files – placed on
                                    your computer, tablet, phone or similar device when you use that
                                    device to access our Services. We may also supplement the
                                    information we collect from you with information received from third
                                    parties, including third parties that have placed their own Cookies
                                    on your device(s). Please note that because of our use of Cookies,
                                    the Services do not support “Do Not Track” requests sent from a
                                    browser at this time.</span>
                            </p>
                        </div>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont12italic">
                                    We use the following types of Cookies:</span>
                            </p>
                            <div className='marginleft20'>
                                <ol start="1">
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normalItalic">
                                                <span className='underlinec'>Essential Cookies.</span> Essential Cookies are required for
                                                providing you with features or services that you have
                                                requested. For example, certain Cookies enable you to log
                                                into secure areas of our Services. Disabling these Cookies
                                                may make certain features and services unavailable.</span>
                                        </p>
                                    </li>
                                </ol>
                                <ol start="2">
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normalItalic">
                                                <span className='underlinec'>Functional Cookies.</span> Functional Cookies are used to record
                                                your choices and settings regarding our Services, maintain
                                                your preferences over time and recognize you when you return
                                                to our Services. These Cookies help us to personalize our
                                                content for you, greet you by name and remember your
                                                preferences (for example, your choice of language or
                                                region).</span>
                                        </p>
                                    </li>
                                </ol>
                                <ol start="3">
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normalItalic">
                                                <span className='underlinec'>Performance/Analytical Cookies.</span> Performance/Analytical
                                                Cookies allow us to understand how visitors use our
                                                Services. They do this by collecting information about the
                                                number of visitors to the Services, what pages visitors view
                                                on our Services and how long visitors are viewing pages on
                                                the Services. Performance/Analytical Cookies also help us
                                                measure the performance of our advertising campaigns in
                                                order to help us improve our campaigns and the Services’
                                                content for those who engage with our advertising. For
                                                example, Google LLC (“Google”) uses cookies in connection
                                                with its Google Analytics services.</span>
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont15normalItalic">
                                    You can decide whether or not to accept Cookies through your
                                    internet browser’s settings. Most browsers have an option for
                                    turning off the Cookie feature, which will prevent your browser from
                                    accepting new Cookies, as well as (depending on the sophistication
                                    of your browser software) allow you to decide on acceptance of each
                                    new Cookie in a variety of ways. You can also delete all Cookies
                                    that are already on your device. If you do this, however, you may
                                    have to manually adjust some preferences every time you visit our
                                    website and some of the Services and functionalities may not work.</span>
                            </p>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <p className="s5 ptag">
                                <span className="bumpedfont15normalItalic">
                                    To explore what Cookie settings are available to you, look in the
                                    “preferences” or “options” section of your browser’s menu. To find
                                    out more information about Cookies, including information about how
                                    to manage and delete Cookies, please visit
                                    <a
                                        className='marginleft5 underlinec'
                                        href="http://www.allaboutcookies.org/"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        http://www.allaboutcookies.org/.
                                    </a>
                                </span></p>
                        </div>
                    </div>
                    <div>

                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>

                        <div>
                            <p className="s5 ptag">
                                <span className="bumpedfont12">
                                    Data Security and Retention</span>
                            </p>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <p className="s5 ptag">
                                <span className="bumpedfont15normalItalic">
                                    We seek to protect your Personal Data from unauthorized access, use
                                    and disclosure using appropriate physical, technical, organizational
                                    and administrative security measures based on the type of Personal
                                    Data and how we are processing that data. You should also help
                                    protect your data by appropriately selecting and protecting your
                                    password and/or other sign-on mechanism; limiting access to your
                                    computer or device and browser; and signing off after you have
                                    finished accessing your account. Although we work to protect the
                                    security of your account and other data that we hold in our records,
                                    please be aware that no method of transmitting data over the
                                    internet or storing data is completely secure.</span>
                            </p>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <p className="s5 ptag">
                                <span className="bumpedfont15normalItalic">
                                    We retain Personal Data about you for as long as you have an open
                                    account with us or as otherwise necessary to provide you with our
                                    Services. In some cases, we retain Personal Data for longer, if
                                    doing so is necessary to comply with our legal obligations, resolve
                                    disputes or collect fees owed, or is otherwise permitted or required
                                    by applicable law, rule or regulation. We may further retain
                                    information in an anonymous or aggregated form where that
                                    information would not identify you personally.
                                </span>
                            </p>
                        </div>

                        <div>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <p className="s5 ptag">
                                <span className="bumpedfont12">
                                    Personal Data of Children</span>
                            </p>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <p className="s5 ptag">
                                <span className="bumpedfont15normalItalic">
                                    As noted in the Terms of Use, we do not knowingly collect or solicit
                                    Personal Data about children under 14 years of age; if you are a
                                    child under the age of 14, please do not attempt to register for or
                                    otherwise use the Services or send us any Personal Data. If we learn
                                    we have collected Personal Data from a child under 14 years of age,
                                    we will delete that information as quickly as possible. If you
                                    believe that a child under 14 years of age may have provided
                                    Personal Data to us, please contact us at
                                    <a
                                        className='marginleft5 underlinec'
                                        href="mailto:help@aiplato.ai"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        help@aiplato.ai
                                    </a>
                                </span>
                            </p>
                        </div>

                        <div>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <p className="s5 ptag">
                                <span className="bumpedfont12 underlinec">
                                    The Children's Internet Protection Act</span>
                            </p>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <p className="s5 ptag">
                                <span className="bumpedfont15normal">
                                    The Children's Internet Protection Act (CIPA) is a federal law
                                    enacted by Congress in December 2000 to address concerns about
                                    access to offensive content over the Internet on school and library
                                    computers. CIPA imposes certain types of requirements on any school
                                    or library that receives funding support for Internet access or
                                    internal connections from the "E-rate" Course - a course that makes
                                    certain technology more affordable for eligible schools and
                                    libraries. Our Services only provide curated links to external
                                    resources related to the educational purpose and do not provide
                                    links/access to chat rooms, and do not contain any offensive or
                                    inappropriate material. If you would like more information about
                                    CIPA, please go to
                                    <a
                                        className='marginleft5'
                                        href="http://www.fcc.gov/cgb/consumerfacts/cipa.html"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        http://www.fcc.gov/cgb/consumerfacts/cipa.html
                                    </a>
                                    .
                                </span>
                            </p>
                        </div>

                        <div>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <p className="s5 ptag">
                                <span className="bumpedfont12 underlinec">
                                    The Family Educational Rights and Privacy Act</span>
                            </p>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <p className="s5 ptag">
                                <span className="bumpedfont15normal">
                                    The Family Educational Rights and Privacy Act (FERPA) is a Federal
                                    law that protects the privacy of student education records. The law
                                    applies to all schools that receive funds under an applicable Course
                                    of the U.S. Department of Education.
                                </span>
                            </p>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <p className="s5 ptag">
                                <span className="bumpedfont15normal">
                                    FERPA gives parents certain rights with respect to their children's
                                    education records. These rights transfer to the student when he or
                                    she reaches the age of 18 or attends a school beyond the high school
                                    level. aiPlato.ai is compliant with FERPA by assuring its Customers
                                    and Users that:
                                </span>
                            </p>
                            <div className='marginleft20'>
                                <ul>
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normal">
                                                Any sensitive online information is transmitted over secure
                                                channels;</span>
                                        </p>
                                    </li>
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normal">
                                                All Customer and User data are stored in ways that are not
                                                publicly accessible; and</span>
                                        </p>
                                    </li>
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normal">
                                                Security audits are regularly performed to ensure data
                                                integrity.</span>
                                        </p>
                                    </li>
                                </ul>

                            </div>

                        </div>



                    </div>
                    <div>

                        <div>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <p className="s5 ptag">
                                <span className="bumpedfont15normal">
                                    aiplaot.ai does not share information with any third parties that
                                    could be used to personally identify Customers or Users. If a
                                    Customer that is an entity, educational institution or school
                                    requests that a client or student's data be sent to a third party,
                                    with student’s consent, aiplato.ai will send the data to the
                                    Customer and never directly to the third party.</span></p>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <p className="s5 ptag">
                                <span className="bumpedfont15normalItalic">If you would like more information about FERPA, please go to
                                    <a
                                        className='marginleft5'
                                        href="http://www.ed.gov/policy/gen/guid/fpco/ferpa/index.html"
                                        target="_blank"
                                        rel="noreferrer noopener">
                                        http://www.ed.gov/policy/gen/guid/fpco/ferpa/index.html
                                    </a>
                                    .</span></p>

                        </div>

                        <div>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <p className="s5 ptag">
                                <span className="bumpedfont12 underlinec">
                                    Children’s Online Privacy Protection Act (COPPA)</span>
                            </p>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <p className="s5 ptag">
                                <span className="bumpedfont15normal"> aiplato.ai is not to be used by users under the age of 14 (fourteen)
                                    years. if you are a child under the age of 14, please do not attempt
                                    to register for or otherwise use the Services or send us any
                                    Personal Data.</span>
                            </p>
                        </div>


                        <div>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <p className="s5 ptag">

                                <span className="bumpedfont12">California</span><span className="bumpedfont12italic"> Resident Rights</span>
                            </p>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <p className="s5 ptag">
                                <span className="bumpedfont15normalItalic"> If you are a California resident, you have the rights set forth in
                                    this section. Please see the “Exercising Your Rights” section below
                                    for instructions regarding how to exercise these rights. Please note
                                    that we may process Personal Data of our customers’ end users or
                                    employees in connection with our provision of certain services to
                                    our customers. If we are processing your Personal Data as a service
                                    provider, you should contact the entity that collected your Personal
                                    Data in the first instance to address your rights with respect to
                                    such data.</span>
                            </p>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <p className="s5 ptag">
                                <span className="bumpedfont15normalItalic">If there are any conflicts between this section and any other
                                    provision of this Privacy Policy and you are a California resident,
                                    the portion that is more protective of Personal Data shall control
                                    to the extent of such conflict. If you have any questions about this
                                    section or whether any of the following rights apply to you, please
                                    contact us at
                                    <a
                                        className='marginleft5'
                                        href="mailto:help@aiplato.ai"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        help@aiplato.ai
                                    </a></span></p>
                        </div>




                        <div>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <p className="s5 ptag">
                                <span className="bumpedfont12italic underlinec">
                                    Access</span>
                            </p>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>
                            <p className="s5 ptag">
                                <span className="bumpedfont15normalItalic">
                                    You have the right to request certain information about our
                                    collection and use of your Personal Data over the past 12 months. In
                                    response, we will provide you with the following information:
                                </span>
                            </p>
                            <div className='marginleft20'>
                                <ol start="1">
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normalItalic">
                                                The categories of Personal Data that we have collected about
                                                you.</span>
                                        </p>
                                    </li>
                                </ol>
                                <ol start="2">
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normalItalic">
                                                The categories of sources from which that Personal Data was
                                                collected.</span>
                                        </p>
                                    </li>
                                </ol>
                                <ol start="3">
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normalItalic">
                                                The business or commercial purpose for collecting or selling
                                                your Personal Data.</span>
                                        </p>
                                    </li>
                                </ol>
                                <ol start="4">
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normalItalic">
                                                The categories of third parties with whom we have shared
                                                your Personal Data.</span>
                                        </p>
                                    </li>
                                </ol>
                                <ol start="5">
                                    <li>
                                        <p className="s5 ptag">
                                            <span className="bumpedfont15normalItalic">
                                                The specific pieces of Personal Data that we have collected
                                                about you.</span>
                                        </p>
                                    </li>
                                </ol>

                            </div>
                            <p className="s5 ptag">
                                <span >&#xa0;</span></p>

                            <p className="s5 ptag">
                                <span className="bumpedfont15normalItalic">
                                    If we have disclosed your Personal Data to any third parties for a
                                    business purpose over the past 12 months, we will identify the
                                    categories of Personal Data shared with each category of third-party
                                    recipients. If we have sold your Personal Data over the past 12
                                    months, we will identify the categories of Personal Data sold to
                                    each category of third-party recipient.
                                </span></p>
                        </div>


                    </div>

                    <div>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont12italic underlinec">
                                Deletion</span>
                        </p>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont15normalItalic">You have the right to request that we delete the Personal Data that
                                we have collected about you. Under the CCPA, this right is subject
                                to certain exceptions: for example, we may need to retain your
                                Personal Data to provide you with the Services or complete a
                                transaction or other action you have requested. If your deletion
                                request is subject to one of these exceptions, we may deny your
                                deletion request.</span></p>
                    </div>

                    <div>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont12italic underlinec">
                                Exercising Your Rights</span>
                        </p>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont15normalItalic">To exercise the rights described above, you or your Authorized Agent
                                (defined below) must send us a request that (1) provides sufficient
                                information (including first name, last name and email address) to
                                allow us to verify that you are the person about whom we have
                                collected Personal Data, and (2) describes your request in
                                sufficient detail to allow us to understand, evaluate and respond to
                                it. Each request that meets both of these criteria will be
                                considered a “Valid Request.” We may not respond to requests that do
                                not meet these criteria. We will only use Personal Data provided in
                                a Valid Request to verify your identity and complete your request.
                                You do not need an account to submit a Valid Request.</span></p>

                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont15normalItalic">We will work to respond to your Valid Request within 45 days of
                                receipt. We will not charge you a fee for making a Valid Request
                                unless your Valid Request(s) is excessive, repetitive or manifestly
                                unfounded. If we determine that your Valid Request warrants a fee,
                                we will notify you of the fee and explain that decision before
                                completing your request.</span></p>


                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont15normalItalic"> You may submit a Valid Request by emailing us at
                                <a
                                    className='marginleft5 underlinec'
                                    href="mailto:help@aiplato.ai"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    help@aiplato.ai
                                </a></span></p>

                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont15normalItalic">You may also authorize an agent (an “Authorized Agent”) to exercise
                                your rights on your behalf. To do this, you must provide your
                                Authorized Agent with written permission to exercise your rights on
                                your behalf, and we may request a copy of this written permission
                                from your Authorized Agent when they make a request on your behalf.</span></p>
                    </div>

                    <div>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont12italic underlinec">
                                Personal Data Sales Opt-Out and Opt-In</span>
                        </p>

                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont15normalItalic">In this section, we use the term ‘sell’ as it is defined in the CCPA. We
                                sell your Personal Data, subject to your right to opt-out of these
                                sales. As described in the “Tracking Tools and Opt-Out” section above,
                                we have incorporated Cookies from certain third parties into our
                                Services. These Cookies allow those third parties to receive information
                                about your activity on our Services that is associated with your browser
                                or device. Those third parties may use that data to serve you relevant
                                ads on our Services or on other websites you visit. Under the CCPA,
                                sharing your data through third party Cookies for online advertising may
                                be considered a “sale” of information. You can opt out of these sales by
                                following the instructions in this section.</span></p>

                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont15normalItalic"> You have the right to opt-out of the sale of your Personal Data. You can
                                opt-out by emailing us at help@aiplato.ai</span></p>


                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont15normalItalic"> Once you have submitted an opt-out request, we will not ask you to
                                reauthorize the sale of your Personal Data for at least 12 months.</span></p>


                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont15normalItalic"> To our knowledge, we do not sell the Personal Data of minors under 14
                                years of age.</span></p>

                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont15normalItalic underlinec">  We Will Not Discriminate Against You for Exercising Your Rights Under
                                the CCPA</span></p>


                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont15normalItalic">  We will not discriminate against you for exercising your rights under
                                the CCPA. We will not deny you our goods or services, charge you
                                different prices or rates, or provide you a lower quality of goods and
                                services if you exercise your rights under the CCPA. However, we may
                                offer different tiers of our Services as allowed by applicable data
                                privacy laws (including the CCPA) with varying prices, rates or levels
                                of quality of the goods or services you receive related to the value of
                                Personal Data that we receive from you.</span></p>

                    </div>


                    <div>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont12">
                                Other State Law Privacy Rights</span>
                        </p>

                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont12italic underlinec">
                                California Resident Rights</span>
                        </p>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont15normalItalic">  Under California Civil Code Sections 1798.83-1798.84, California
                                residents are entitled to contact us to prevent disclosure of Personal
                                Data to third parties for such third parties’ direct marketing purposes;
                                in order to submit such a request, please contact us at
                                <a
                                    className='marginleft5 underlinec'
                                    href="mailto:help@aiplato.ai"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    help@aiplato.ai
                                </a></span></p>
                    </div>
                    <div>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont12italic">
                                Nevada Resident Rights</span>
                        </p>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont15normalItalic"> If you are a resident of Nevada, you have the right to opt-out of the
                                sale of certain Personal Data to third parties who intend to license or
                                sell that Personal Data. You can exercise this right by contacting us at
                                <a
                                    className='marginleft5 marginright5 underlinec'
                                    href="mailto:help@aiplato.ai"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    help@aiplato.ai
                                </a>
                                with the subject line “Nevada Do Not Sell Request” and providing us with
                                your name and the email address associated with your account. Please
                                note that we do not currently sell your Personal Data as sales are
                                defined in Nevada Revised Statutes Chapter 603A.</span></p>
                    </div>







                    <div>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont12">
                                Changes to this Privacy Policy</span>
                        </p>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont15normalItalic">  We’re constantly trying to improve our Services, so we may need to
                                change this Privacy Policy from time to time, but we will alert you to
                                any such changes by placing a notice on the aiPlato website, by sending
                                you an email and/or by some other means. Please note that if you’ve
                                opted not to receive legal notice emails from us (or you haven’t
                                provided us with your email address), those legal notices will still
                                govern your use of the Services, and you are still responsible for
                                reading and understanding them. If you use the Services after any
                                changes to the Privacy Policy have been posted, that means you agree to
                                all of the changes. Use of information we collect is subject to the
                                Privacy Policy in effect at the time such information is collected.</span></p>
                    </div>




                    <div>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont12">
                                Contact Information:</span>
                        </p>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont15normalItalic"> If you have any questions or comments about this Privacy Policy, the
                                ways in which we collect and use your Personal Data or your choices and
                                rights regarding such collection and use, please do not hesitate to
                                contact us at:</span></p>


                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont15normalItalic">  Website:
                                <a className='marginleft5 underlinec' href="https://aiplato.ai/" target="_blank" rel="noreferrer noopener">
                                    https://aiplato.ai
                                </a></span></p>


                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <p className="s5 ptag">
                            <span className="bumpedfont15normalItalic">  Email:
                                <a
                                    className='marginleft5 underlinec'
                                    href="mailto:help@aiplato.ai"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    help@aiplato.ai
                                </a></span></p>
                    </div>


                </Container >

            </div >
        </>

    )
}

export default PrivacyPolicy