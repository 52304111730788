import React, { useEffect, useState } from 'react';
import { isNil, orderBy } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import {
    Avatar,
    makeStyles

} from '@material-ui/core';
import './dashboard.scss'
import { getPTTeachers, getStudentDetails } from '../../common/API';



function Dashboard() {
    const [teacherOptions, setTeacherOptions] = useState(null)
    const [selectedTeacher, setSelectedTeacher] = useState(-1)
    const [studentsList, setStudentsList] = useState([])

    const useStyles = makeStyles((theme) => ({
        root: {},
        avatar: {
            height: 42,
            width: 42,
            marginRight: theme.spacing(1)
        },
        avatarlarge: {
            height: 150,
            width: 150,
        },
        linkbutton: {
            fontSize: "10px"
        },
    }));

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#a4a4a4",
            color: theme.palette.common.white,
            padding: "10px",
            fontSize: 16,
            fontWeight: "bold"
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            padding: "7px"
        },
    }));


    const StyledTableCellbody = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            //backgroundColor: theme.palette.common.black,
            //color: theme.palette.common.white,
            padding: "10px",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 12,
            padding: "7px"
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    useEffect(() => {

        getPTTeachers().then(res => {
            let teachersData = []
            if (!isNil(res) && !isNil(res.data)) {
                res.data.data.forEach(element => {
                    teachersData.push({ value: element.numberofstudents, name: element.name, email: element.email, userId: element.userId })
                });

                teachersData = orderBy(teachersData, ['value'], ['desc']);
                console.log(teachersData);
                setSelectedTeacher(teachersData[0]['userId'])
            }

            setTeacherOptions({

                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {

                        return `${params.data.email}<br />
                              ${'Name'}: ${params.data.name} <br />
                              ${'No. of Students'}: ${params.data.value}`;
                    }
                },
                // legend: {
                //     orient: 'vertical',
                //     left: 'left'
                // },
                labelLine: {
                    show: false
                },
                series: [
                    {
                        name: 'Teacher',
                        type: 'pie',
                        radius: '50%',
                        data: teachersData,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            })
        })




    }, []);

    useEffect(() => {
        if (!isNil(teacherOptions)) {
            var teacherChart = window.echarts.init(document.getElementById('teacherChart'));
            teacherChart.setOption(teacherOptions);
            teacherChart.on('click', function (params) {
                // Print name in console
                setSelectedTeacher(params.data.userId);
            });
        }

    }, [teacherOptions])

    useEffect(() => {
        if (selectedTeacher !== -1) {
            getStudentDetails(selectedTeacher).then((res) => {
                setStudentsList(res.data.data)
            })
        }
    }, [selectedTeacher])

    return (
        <div className='dashboardContainer'>
            <div id='teacherChart' className='teacherChart'></div>
            <div className='studentChart'>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>User Id</StyledTableCell>
                                <StyledTableCell>Email</StyledTableCell>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Attempted</StyledTableCell>
                                <StyledTableCell>Completed</StyledTableCell>
                                {/* <StyledTableCell>Completed Practice Test</StyledTableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                studentsList !== undefined && studentsList !== null && studentsList.length > 0 ? (
                                    studentsList.map((row) => {
                                        row.attempted = ''
                                        if (row.attemptedpt !== undefined && row.attemptedpt !== null && row.attemptedpt.length > 0) {
                                            row.attemptedpt.forEach((element,index) => {
                                                row.attempted += index === 0 ? element.title : ","+element.title
                                            })
                                        }
                                        row.completed = ''
                                        if (row.submittedpt !== undefined && row.submittedpt !== null && row.submittedpt.length > 0) {
                                            row.submittedpt.forEach((element,index) => {
                                                row.completed += index === 0 ? element.title : ","+element.title
                                            })
                                        }

                                        return (
                                            <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                                <StyledTableCellbody component="th" scope="row">
                                                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                        {/* <Avatar
                                                            className={classes.avatar}
                                                            src={imgURL + row.profile_pic}>
                                                        </Avatar> */}
                                                        <div className='mt-2'>{row.userId}</div>
                                                    </div>
                                                </StyledTableCellbody>
                                                <StyledTableCellbody >
                                                    {row.name}
                                                </StyledTableCellbody>
                                                <StyledTableCellbody >
                                                    {row.email}
                                                </StyledTableCellbody>
                                                <StyledTableCellbody >
                                                    {row.attempted}
                                                </StyledTableCellbody>
                                                <StyledTableCellbody >
                                                    {row.completed}
                                                </StyledTableCellbody>
                                                {/* <StyledTableCellbody >
                                                    <Button variant="text" onClick={(e) => openmodalhandle(e, row.studentid, row.studentname)} style={{ fontSize: "12px", textTransform: "none" }}>View</Button>
                                                </StyledTableCellbody>
                                                {getIsPTTeacher() === 'true' ?
                                                    <StyledTableCellbody >
                                                        {attemptpttestcontent}
                                                    </StyledTableCellbody> : null} */}
                                            </StyledTableRow>
                                        )
                                    }
                                    )
                                ) : (<div style={{ padding: "20px" }}>No Student Found!</div>)
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )

}

export default Dashboard;