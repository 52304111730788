import React from 'react';
import './TermsandCondition.scss';
import aiplatologo from '../../assets/images/aiplatoplogo.png';
import { Row, Col, Container } from "react-bootstrap"


const TermsandCondition = () => {
    return (
        <>
            <div className='fwidth'>
                <Container className="secpad">
                    <div>

                        <p style={{ textAlign: "center" }}>
                            <img src={aiplatologo} width="67" height="53" alt="" />
                        </p>
                    </div>
                    <div>
                        <h1 style={{ textAlign: "center" }}>
                            <span className="bumpedfont15">
                                aiPlato Terms of Use</span></h1>

                    </div>
                    <p className="s5">
                        <span className="bumpedfont15normal">
                            Please read these terms and conditions ("terms and conditions", "terms") carefully before using
                            https://aiplato.ai website</span>
                        <span className="apple-converted-space">&#xa0;</span>
                        <span className="bumpedfont15normal">(“website”, "service") operated by aiPlato, Inc.. ("us", 'we", "our").</span>
                    </p>

                    <p className="s5 ptag">
                        <span >&#xa0;</span></p>
                    <p className="s5 ptag">
                        <span className="bumpedfont12">
                            Conditions of use</span></p>
                    <p className="s5 ptag">
                        <span className="bumpedfont15normal">By using this website, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to stop using the website accordingly. aiPlato, Inc. only grants use and access of this website, its products, and its services to those who have accepted its terms. </span>
                    </p>

                    <p className="s5 ptag">
                        <span >&#xa0;</span></p>
                    <p className="s5 ptag">
                        <span className="bumpedfont12">
                            Privacy policy </span></p>

                    <p className="s5 ptag">
                        <span className="bumpedfont15normal">
                            Before you continue using our website, we advise you to read our <a className='underlinec' target="_blank" href='/privacypolicy'> Privacy policy </a> regarding our user data collection.
                            It will help you better understand our practices.
                        </span></p>



                    <p className="s5 ptag">
                        <span >&#xa0;</span></p>
                    <p className="s5 ptag">
                        <span className="bumpedfont12">
                            Age restriction</span></p>
                    <p className="s5 ptag">
                        <span className="bumpedfont15normal">
                            You must be at least 14 (fourteen) years of age before you can use this website. By using this website, you warrant that you are at
                            least 14 years of age, and you may legally adhere to this Agreement. aiPlato, Inc. assumes no responsibility for liabilities related to age
                            misrepresentation.
                        </span>
                    </p>



                    <p className="s5 ptag">
                        <span >&#xa0;</span></p>
                    <p className="s5 ptag">
                        <span className="bumpedfont12">
                            Intellectual property</span></p>
                    <p className="s5 ptag">
                        <span className="bumpedfont15normal">
                            You agree that all materials, products, and services provided on this website are the property of aiPlato, Inc.,
                            its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents,
                            and other intellectual property. You also agree that you will not reproduce or redistribute the aiPlato, Inc.’s intellectual property in any way,
                            including electronic,
                            digital, or new trademark registrations.
                        </span>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <span className="bumpedfont15normal">
                            You grant aiPlato, Inc. a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish.
                            <span style={{ textDecoration: "dotted", textDecorationColor: "#007bff" }}> For issues regarding intellectual property claims, you should contact the company in order to come to an agreement.</span>
                        </span>
                    </p>


                    <p className="s5 ptag">
                        <span >&#xa0;</span></p>
                    <p className="s5 ptag">
                        <span className="bumpedfont12">
                            User accounts </span></p>

                    <p className="s5 ptag">
                        <span className="bumpedfont15normal">
                            As a user of this website, you may be asked to register with us and provide private information.
                            You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security
                            of your identifying information. You are also responsible for all activities that occur under your account or password.
                        </span>
                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <span className="bumpedfont15normal">
                            If you think there are any possible issues regarding the security of your account on the website, inform us immediately so we may address them accordingly.
                            We reserve all rights to terminate accounts, edit or remove content and cancel orders at our sole discretion.
                        </span>
                    </p>


                    <p className="s5 ptag">
                        <span >&#xa0;</span></p>
                    <p className="s5 ptag">
                        <span className="bumpedfont12">
                            Applicable law </span></p>
                    <p className="s5 ptag">
                        <span className="bumpedfont15normal">
                            By using this website, you agree that the laws of the Delaware, without regard to principles of conflict laws,
                            will govern these terms and conditions,
                            or any dispute of any sort that might come between aiPlato, Inc. and you, or its business partners and associates.
                        </span>
                    </p>



                    <p className="s5 ptag">
                        <span >&#xa0;</span></p>
                    <p className="s5 ptag">
                        <span className="bumpedfont12">
                            Disputes </span></p>
                    <p className="s5 ptag">
                        <span className="bumpedfont15normal">
                            Any dispute related in any way to your use of this website or to products you purchase from us
                            shall be arbitrated by state or federal court Delaware and you consent to exclusive jurisdiction and venue of such courts.
                        </span>
                    </p>


                    <p className="s5 ptag">
                        <span >&#xa0;</span></p>
                    <p className="s5 ptag">
                        <span className="bumpedfont12">
                            Indemnification  </span></p>
                    <p className="s5 ptag">
                        <span className="bumpedfont15normal">
                            You agree to indemnify aiPlato, Inc. and its affiliates and hold aiPlato, Inc. harmless against legal claims and demands that may
                            arise from your use or misuse of our services. We reserve the right to select our own legal counsel.
                        </span>
                    </p>

                    <p className="s5 ptag">
                        <span >&#xa0;</span></p>
                    <p className="s5 ptag">
                        <span className="bumpedfont12">
                            Limitation on liability   </span></p>
                    <p className="s5 ptag">
                        <span className="bumpedfont15normal">
                            aiPlato, Inc. is not liable for any damage that may occur to you as a result of your misuse of our website.
                        </span>

                        <p className="s5 ptag">
                            <span >&#xa0;</span></p>
                        <span className="bumpedfont15normal">
                            aiPlato, Inc. reserves the right to edit, modify, and change this Agreement at any time. We shall let our users know of these changes
                            through electronic mail. This Agreement is an understanding between aiPlato, Inc. and the user,
                            and this supersedes and replaces all prior agreements regarding the use of this website.
                        </span>
                    </p>
                </Container>

            </div >
        </>

    )
}

export default TermsandCondition