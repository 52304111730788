import React, { Component } from 'react';
import { Row, Col, Container } from "react-bootstrap"
import './home.scss';
import { isNil, random } from 'lodash';
import { checkCredentialsAPI, validIds, saveToDbAPI, SaveUserProfile, saveWebsiteVisitors, websitevisitorsconverted } from '../../common/API'
import { toast } from 'react-toastify';
import secimg from "../../assets/images/secimg.svg"
import Cookies from 'universal-cookie';
import img5 from "../../assets/images/5.jpg"
import img6 from "../../assets/images/6.jpg"
import home1 from "../../assets/images/dashboard-img.png"
// import home2 from "../../assets/images/dashboard-img3.png";
import home2 from "../../assets/images/home-img-1-on-1-img.png";
// import home3 from "../../assets/images/home-img-2.png";
import home3 from "../../assets/images/home-img-solution.png";
import Carousel from 'better-react-carousel'
import Accordion from "../HomeAccordion/HomeAccordion";

import Emily from '../../assets/images/student1.png';
import Vikram from '../../assets/images/student2.png';
import Aisha from '../../assets/images/student3.png';
import John from '../../assets/images/student4.png';
import practiceTestDemoVideo from "../../assets/videos/aiPlato_Practice_Tests_edited.mp4";
import {
    APP_URL, setCookies, checkIsPrivate, setIsPrivate, setToken, token, gtoken, getToken, demoUserId
    , WEBSITE_URL, getlocalsystemtimezone, getUserAgent, showFullApp
} from '../../common/Functions';
import quoteSignImg from '../../assets/images/img-quote-sign.svg';

import ellipse1Img from '../../assets/images/ellipse11.png';
import ellipse2Img from '../../assets/images/ellipse5.png';
import ellipse4Img from '../../assets/images/ellipse4.png';

import plusLightBlueImg from '../../assets/images/plus-light-blue.svg';
import equalLightBlueImg from '../../assets/images/equal-light-blue.svg';
import aPlusIcon2 from '../../assets/images/icon-a-plus-thin.svg';
import touchIcon from '../../assets/images/icon-touch-fill.svg';
import mindDotsIcon from '../../assets/images/icon-mind-dots.svg';

import graduateStudentIcon from '../../assets/images/icon-graduate-student.svg';
import proficincyIcon from '../../assets/images/icon-proficincy.svg';
import puzzleIcon from '../../assets/images/icon-puzzle.svg';

import Acc1aImg from '../../assets/images/Acc-1a.png';
import Acc1bImg from '../../assets/images/Acc-1b.png';
import Acc1cImg from '../../assets/images/Acc-1c.png';
import Acc1dImg from '../../assets/images/Acc-1d.png';

import Acc2aImg from '../../assets/images/Acc-2a.png';
import Acc2bImg from '../../assets/images/Acc-2b.png';
import Acc2cImg from '../../assets/images/Acc-2c.png';
import Acc2dImg from '../../assets/images/Acc-2d.png';
import Acc2eImg from '../../assets/images/Acc-2e.png';

import Acc3aImg from '../../assets/images/Acc-3a.png';
import Acc3bImg from '../../assets/images/Acc-3b.png';
import Acc3cImg from '../../assets/images/Acc-3c.png';
import videoHome from '../../assets/images/video_aiPlato.mp4'
import infoIcon from '../../assets/images/info_icon.png'
import videoHomeBottom from '../../assets/images/video_aiPlato_Proficiency_Curve_vs_Traditional_v2.mp4'
import whoIsiaPlatoImg from '../../assets/images/img-who-is-aiPlato.png';
import { ClientJS } from 'clientjs';
import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Stack from '@mui/material/Stack';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    '@media (max-width: 600px)': {
        width: '350px !important',
        p: 1
    }

};


class Home extends Component {

    state = {
        email: '',
        password: '',
        validIds: [],
        showPassword: false,
        shoowearlyaccess: true,
        showemaildiv: false,
        showdropdown: true,
        studenttutorvalue: "Student",
        buttontext: "Request Access",
        carousalCardCount: 3,
        reviews: [{ name: 'Emily, Highschool, CA', image: Emily, text: 'This is amazing; How did this AI know what I was thinking when I made this error!' },
        { name: 'Vikram V., Highschool, MD', image: Vikram, text: 'This helps me learn better than anything I have ever used. It knows what I know and what I don’t, and uses it to fine-tune every sentence! Like a live tutor! Super helpful!' },
        { name: 'Aisha S., Highschool, CA', image: Aisha, text: 'For the first time I feel that I thoroughly understand every concept. And I had thought that I’ll never get Physics' },
        { name: 'Nicole O., University, MA', image: Aisha, text: 'I’ve tried all AI stuff for homework. aiPlato is different. You really get the hang of these problems on aiPlato.' },
        { name: 'Eric C., AP Physics, Homeschool, CA', image: Aisha, text: 'These lectures are cool; I can ask anything. And it’s crazy how it answers like this AI figured out *why* I am confused.' },
        { name: 'John D., University, TX', image: John, text: 'With this, I really learn it! I can feel it! I think, I can ace it!' },
            //     { name: 'John 123', image: John, text: 'With this, I really learn it! I can feel it! I think, I can ace it!' },        
        ],
        responsiveLayout: [
            {
                breakpoint: 768,
                cols: 2,
                rows: 1,
                gap: 10,
                loop: true,
                autoplay: 5000
            },
            {
                breakpoint: 1024,
                cols: 3,
                rows: 1,
                gap: 10,
                loop: true,
                autoplay: 5000
            },
        ],
        accordionSection1: [],
        accordionSection2: [],
        isPrivate: false,
        isOpen: false,
        bannerOpen: false,
        showModalVideo: false,
        whiteBoardVideoDiv: '',
    }

    componentWillMount() {
        const cookies = new Cookies();
        this.setState({ isPrivate: true })
        setIsPrivate(true);

        /*
            try {
                const queryString = window.atob(window.location.search.slice(1));
                let queryParamsArry = queryString.split("=")[1];
                if (getToken() !== null) {
                    this.setState({ isPrivate: true })
                    setIsPrivate(true);
                }
                else if (!isNil(queryParamsArry)) {
                    getPrivateDetail(queryParamsArry).then(res => {
                        if (res.data.allow === 'True') {
                            this.setState({ isPrivate: true })
                            setIsPrivate(true);
                            setToken(queryParamsArry)
                        } else {
                            this.setState({ isPrivate: false })
                            cookies.set('isPrivate', 'false');
                            setIsPrivate(false)
                        }
                    });


                }
                else {
                    setIsPrivate(false)
                    this.setState({ isPrivate: false })

                }

            } catch (err) {
                console.log(err);
                cookies.set('isPrivate', 'false')
                this.setState({ isPrivate: false })
                cookies.set('PrivateToken', null)
            }
        */

        let data1 = {
            headerIconPath: graduateStudentIcon,
            headingText: "Lectures That Listen and Respond",
            headingDescription: "Interactive AI Tutor",
            imgOnLeft: false,
            imgSectionClass: 'yellow',
            accordionSectionClass: 'px-0',
            isFirstOpen: true,
            accordionDetails: [
                {
                    headerText: "Your Questions, Instantly Addressed",
                    description: "Experience the nuance of a tutor who understands the roots of your confusion, offering precise clarification in real-time.",
                    imagePath: Acc1bImg,
                },
                {
                    headerText: "Dive Deeper with Interactive Elements",
                    description: "Purpose-built AI engages you with content that is personalized to your strengths and weaknesses.",
                    imagePath: Acc1aImg,
                },
                {
                    headerText: "Real-Time Feedback",
                    description: "Benefit from immediate, accurate responses to your in-lecture queries, allowing you to grasp complex concepts the moment they're discussed.",
                    imagePath: Acc1cImg,
                }]
        }

        let data = {
            headerIconPath: puzzleIcon,
            headingText: "Homework and Tests Prep that Teach",
            headingDescription: "Become an Expert Problem Solver",
            imgOnLeft: true,
            imgSectionClass: 'blue',
            accordionSectionClass: 'px-0',
            isFirstOpen: true,
            accordionDetails: [{
                headerText: "Get step-by-step feedback, master Every Problem",
                description: "Learn with a 24/7 AI tutor that provides step-by-step feedback, understanding your thought process and guiding you through each problem with targeted interventions and insights.",
                imagePath: Acc2aImg,
            },
            {
                headerText: "Customized Problem-Solving Practice",
                description: "Advance your skills with an AI that adapts to your needs, offering personalized problem-solving sessions that help you tackle challenges and learn topics in-depth, anytime, anywhere.",
                imagePath: Acc2bImg,
            },
            {
                headerText: "Bring any problem, any topic ",
                description: "Upload your own problems and let our AI tutor break it down for you, offering clear, step-by-step guidance that ensures you not only find the answer but understand the how and why behind it.",
                imagePath: Acc2dImg,
            }
                // ,
                // {
                //     headerText: "Bring Any Problem, Any Topic",
                //     description: "Unpack any challenge or learn any topic. If you have a unique problem or topic, simply upload it, and learn step-by-step with the 1-on-1 AI tutor!",
                //     imagePath: Acc2dImg,
                // },
                // {
                //     headerText: "One-Click Human Tutor Access",
                //     description: "And when you want it, a human tutor is just one click away.",
                //     imagePath: Acc2eImg,
                // }
            ],
        }
        let data2 = {
            headerIconPath: proficincyIcon,
            headingText: "Personalized Teaching for Your Proficiency",
            headingDescription: "Master Concepts, Boost Confidence & Grades",
            imgOnLeft: false,
            imgSectionClass: 'yellow',
            accordionSectionClass: 'px-0',
            isFirstOpen: true,
            accordionDetails: [{
                headerText: "Personalized Lectures, Problems, and Interactions",
                description: "Learn from a tutor who understands your strengths and weaknesses, to personalize your learning – to your needs, your struggles, your proficiency map.",
                imagePath: Acc3aImg,
            },
            {
                headerText: "Focus Time & Effort for Most Impact",
                description: "Target your study time on your proficiency needs, at the specificity of each micro-concept level.",
                imagePath: Acc3bImg,
            }],
        }

        this.setState({ accordionSection1: data, accordionSection2: data1, accordionSection3: data2 })
    }

    savewebsitevisitors = () => {
        //debugger;
        const client = new ClientJS();
        const fingerprint = client.getFingerprint();
        const form_data = new FormData();
        //     debugger;

        form_data.append("userId", fingerprint)
        form_data.append('user_agent', getUserAgent());
        form_data.append("ref_url", document.referrer);
        form_data.append("browsing_detail", "")
        console.log("formdata", form_data)
        saveWebsiteVisitors(form_data).then(res => {
        })
    }

    componentDidMount() {
        this.fetchIds()
        let studentContainer = document.getElementById('studentsContainer');
        this.setState({ carousalCardCount: Math.floor(studentContainer.clientWidth / 280) })
        this.savewebsitevisitors()
        // debugger;
        const cookies = new Cookies();
        let cookiebannervalue = cookies.get("cookieconcentbanner")
        if (cookiebannervalue === false || cookiebannervalue === "false") {
            this.setState({ bannerOpen: false })
        }
        else {
            this.setState({ bannerOpen: true })
        }


    }
    closeBanner = () => {
        debugger;
        const cookies = new Cookies();
        // const hostnameParts = window.location.hostname.split('.');
        // const domainName = hostnameParts.length > 1 ? hostnameParts[hostnameParts.length - 2] + "." + hostnameParts[hostnameParts.length - 1] : hostnameParts[hostnameParts.length - 1];
        cookies.set('cookieconcentbanner', false);
        this.setState({ bannerOpen: false })
    }

    handleEmailChange = (event) => {

        let email = event.target.value.trim()
        this.setState({ email: email }, () => {
            if (this.state.validIds.includes(email.toLowerCase())) {
                this.setState({ showPassword: true, showdropdown: false, buttontext: "Login" });
            } else {
                this.setState({ showPassword: false, showdropdown: true });
            }
        });

    }



    isValidEmail = (email) => {
        return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    }

    getEarlyAccessHandler = (e) => {

        e.preventDefault()
        if (this.state.password.length > 0) {
            let data = { 'email': this.state.email.trim().toLowerCase(), password: this.state.password }

            checkCredentialsAPI(data).then(res => {

                console.log(res)
                if (res.status === 200) {
                    if (res.data['found'] === true) {
                        this.addValidationCookie(this.state.email.trim().toLowerCase(),
                            res.data.userId,
                            res.data.role,
                            res.data.name,
                            res.data.status,
                            res.data.tryThisPinsEnabled,
                            res.data.QATestFlag,
                            this.convertDurationToSeonds(res.data.timeLimit),
                            res.data.institute_id,
                            res.data.user_timezone, "", false, res.data.isDemoUser, res.data.isPtTeacher)
                        toast.success("Valid Demo Credentials!", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        window.open(APP_URL, '_self')
                        this.setState({ email: '', password: '', showPassword: false });
                        this.setState({ shoowearlyaccess: true, showemaildiv: false, showdropdown: true, studenttutorvalue: "Student", buttontext: "Request Access" })



                    }
                    else if (res.data['isexpired'] === true) {
                        toast.error("Your account is expired, please get in touch with support team!", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        this.setState({ shoowearlyaccess: true, showemaildiv: false })
                    }
                    else {
                        toast.error("Please enter valid email id and password.", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        this.setState({ shoowearlyaccess: false, showemaildiv: true })
                    }




                } else {
                    toast.error("Server Error !", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            }).catch(err => {
                console.error(err.message)
                toast.error("Please enter valid email id and password.", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
        } else {
            // save email to db
            let data = { 'email': this.state.email.trim().toLowerCase(), studenteducatior: this.state.studenttutorvalue == "" ? "Student" : this.state.studenttutorvalue }
            saveToDbAPI(data).then(res => {
                toast.success("Thank you for submitting your early access request. Our team will be in touch with you shortly to provide further information and support.", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                window.open(`${WEBSITE_URL}earlyaccesspriorityinfo/${res.data.data}`)
            }).catch(err => {
                console.error(err.message)
                toast.warning("We already have your email in our system, someone from our team contact you soon.", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                this.setState({ email: '' });
            })
        }
    }

    addValidationCookie = (email, userId, role, name, status, tryThisPinsEnabled, QATestFlag, timeLimit, institute_id, usertimezone, user_fingerprint, isDemoUser, isDemoUserFlagged, isPtTeacher) => {
        setCookies(email, userId, role, name, status, tryThisPinsEnabled, QATestFlag, timeLimit, institute_id, usertimezone, user_fingerprint, isDemoUser, isDemoUserFlagged, isPtTeacher);
    }

    fetchIds = () => {

        validIds().then(res => {
            this.setState({ validIds: res.data.ids.map(item => atob(item)) });
        }).catch(err => {
            console.error(err.message)
            toast.error("Error fetching ids!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })

    }

    hasValidValues = () => {
        return this.state.email.trim().length > 0
            && this.isValidEmail(this.state.email.trim())
    }

    convertDurationToSeonds = (duration) => {
        const a = duration.split(':');
        return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    }
    showemailhandler = () => {
        this.setState({ shoowearlyaccess: false, showemaildiv: true })
    }

    opentestappdemovideo = (e) => {
        e.preventDefault();
        this.setState({
            showModalVideo: true,


        })

    }
    closeVideoModal = () => {
        this.setState({ showModalVideo: false })
    }

    openDemoApp = (e) => {
        e.preventDefault();


        const client = new ClientJS();
        const fingerprint = client.getFingerprint();
        const form_data = new FormData();

        form_data.append("Username", fingerprint)
        form_data.append("course", "Physics")
        form_data.append("useruniquecode", fingerprint)
        form_data.append("signupmethod", "tryitout")
        form_data.append("Password", "")
        form_data.append("referURL", document.referrer);
        form_data.append('userAgent', getUserAgent());
        form_data.append("Timezone", getlocalsystemtimezone())
        form_data.append("Emailid", fingerprint + "@aiplato.ai")

        SaveUserProfile(form_data).then(res => {
            if (res.data.Success === "exist" || res.data.Success === "Success") {
                let data = { 'email': fingerprint + "@aiplato.ai", password: "", signupmethod: "tryitout" }
                checkCredentialsAPI(data).then(res => {
                    if (res.status === 200) {

                        form_data.append("userId", fingerprint)
                        form_data.append('converted_from', "T");
                        form_data.append("userdata", fingerprint + "@aiplato.ai")
                        websitevisitorsconverted(form_data).then(res => {
                        })

                        if (res.data['found'] === true) {
                            this.addValidationCookie(fingerprint + "@aiplato.ai",
                                res.data.userId,
                                res.data.role,
                                res.data.name,
                                res.data.status,
                                res.data.tryThisPinsEnabled,
                                res.data.QATestFlag,
                                this.convertDurationToSeonds(res.data.timeLimit),
                                res.data.institute_id,
                                res.data.user_timezone,
                                fingerprint,
                                true,
                                res.data.isDemoUser)
                            toast.success("Valid Demo Credentials!", {
                                position: toast.POSITION.BOTTOM_RIGHT
                            });
                            if(showFullApp()){
                                window.open(APP_URL + "curriculum-problems", '_self')
                            }else {
                                window.open(APP_URL, '_self')
                            }
                            
                        }
                        else if (res.data['isexpired'] === true) {
                            toast.error("Your account is expired, please get in touch with support team!", {
                                position: toast.POSITION.BOTTOM_RIGHT
                            });
                            this.setState({ shoowearlyaccess: true, showemaildiv: false })
                        }
                        else {
                            toast.error("Please enter valid email id and password.", {
                                position: toast.POSITION.BOTTOM_RIGHT
                            });
                            this.setState({ shoowearlyaccess: false, showemaildiv: true })
                        }
                    } else {
                        toast.error("Server Error !", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    }
                }).catch(err => {
                    console.error(err.message)
                    toast.error("Please enter valid email id and password.", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                })
            }
        }).catch(err => {
            toast.error("Error!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            console.error(err.message)
        })




        // this.addValidationCookie("demo@aiplato.ai", demoUserId, 'Student', 'Demo', 'Active', 'no', true, 35000, 1, 'America/Los_Angeles');
        // window.open(APP_URL, '_self');
    }
    handlestudenttutorchange = (e) => {
        this.setState({ studenttutorvalue: e.target.value })
    }

    MyDot = ({ isActive }) => (
        <span
            style={{
                display: 'inline-block',
                height: isActive ? '8px' : '5px',
                width: isActive ? '8px' : '5px',
                background: '#1890ff'
            }}
        ></span>
    )


    bannerOpen

    render() {
        return (

            // ======= NEW=========           
            this.state.isPrivate ?
                <>
                    <div>
                        <div className='container-fluid py-5 bg-light-blue2'>
                            <div className='container '>
                                <Row className='sectionOne'>
                                    <Col sm={12} lg={5} className='sectionOneWhite'>
                                        <div className='bannerTitleWhite'>

                                            <h1 className='bannerTitle '>STEM AI Tutor</h1> <h3 className='bannerTitle'>Loved by Students</h3> <h3 className='bannerTitle'>Developed by Educators</h3>
                                            <p className='bannerText text-black'><a href="#firstAccordian">Students,</a> meet your 24/7 Personal Tutor.<br className='d-none d-md-block' /><a href="/teacher">Educators,</a> meet your AI Teaching Assistant.</p>
                                            {/* <img src={strokeImg} alt='' className='stroke-img' /> */}
                                        </div>

                                        <div className="input-group p-0 pr-lg-3 ">
                                            <form className="">
                                                {
                                                    this.state.shoowearlyaccess ? (
                                                        <div>
                                                            <button
                                                                onClick={this.openDemoApp}
                                                                className="btnMain"
                                                            >Try It Out</button> &nbsp;
                                                            {/* <button
                                                    onClick={this.showemailhandler}
                                                    className="btnMain"
                                                >Get Early Access</button> */}

                                                            <Link to={`/signup`}
                                                                className="btnMain">
                                                                Get Early Access</Link>
                                                        </div>
                                                    ) : null
                                                }
                                                {
                                                    this.state.showemaildiv ? (
                                                        <>
                                                            <div class="form-group mb-2">
                                                                <input
                                                                    autoFocus
                                                                    type="email"
                                                                    className="form-control mr-2 inp mobinpst "
                                                                    value={this.state.email}
                                                                    onChange={this.handleEmailChange}
                                                                    placeholder="Enter your email address" />
                                                            </div>
                                                            {this.state.showPassword ? <><div class="form-group mb-2">
                                                                <input
                                                                    type="password"
                                                                    className="form-control mr-2 inp mobinpst "
                                                                    value={this.state.password}
                                                                    onChange={(e) => this.setState({ password: e.target.value })}
                                                                    placeholder="Enter your password" />
                                                            </div>

                                                            </> : null}

                                                            {this.state.showdropdown ? <><div class="form-group mb-2">
                                                                <select onChange={this.handlestudenttutorchange} id="studenttutor" name="studenttutor" className="form-control mr-2 inp mobinpst ">
                                                                    <option value="Student" selected>I am a Student</option>
                                                                    <option value="Educator" >I am an Educator</option>


                                                                </select>
                                                            </div>

                                                            </> : null}

                                                            <button
                                                                onClick={this.getEarlyAccessHandler}
                                                                disabled={!this.hasValidValues()}
                                                                className="btnMain"
                                                                type="submit">{this.state.buttontext}</button>

                                                        </>
                                                    ) : null
                                                }

                                            </form>
                                        </div>
                                        {/* <a href='#' className='btnMain'>Get Early Access</a> */}
                                        {/* <p className='copyText pr-2 mb-0 pt-4'>The most advanced AI tutor for STEM:</p>
                            <ul className='copyText bannerUlText'>
                                <li>Ace every concept, every homework, every test </li>
                                <li>Learn faster, without struggle</li>
                                <li>Experience Interactive, Personalized, Engaging Learning.</li>
                            </ul> */}
                                    </Col>
                                    <Col sm={12} lg={7} className='text-md-right text-center'>
                                        {/* <img src={heroImg} alt='' className='img-fluid heroImg' /> */}
                                        <video src={videoHome} className='videoPlayer' width="100%" autoPlay loop muted preload='meta' ></video>

                                    </Col>
                                </Row>
                            </div>

                        </div>


                        <div className='container-fluid py-5 bg-light-blue2'>
                            <div className='container'>
                                <Row className='ellipseSection'>
                                    <Col sm={12} md={3} className='banner2Text'>
                                        <img src={ellipse1Img} alt='' className='img-fluid' />
                                        <h5 className=''>Deep Research in Education</h5>
                                        <p className='copyText mb-0 '>aiPlato Harvard and Stanford education researchers have created groundbreaking insights on how expert 1-on-1 tutors boost learning.</p>
                                    </Col>
                                    <Col sm={12} md={1} className='text-center'><img src={plusLightBlueImg} alt='' className='img-fluid blackArrowCircle' /></Col>
                                    <Col sm={12} md={3} className='banner2Text'>
                                        <img src={ellipse2Img} alt='' className='img-fluid' />
                                        <h5 className=''>Purpose-Built Gen AI</h5>
                                        <p className='copyText mb-0 '>aiPlato AI experts have meticulously embodied   insights from education research in purpose-built AI.</p>
                                    </Col>
                                    <Col sm={12} md={1} className='text-center'><img src={equalLightBlueImg} alt='' className='img-fluid blackEqualCircle' /></Col>
                                    <Col sm={12} md={3} className='banner2Text'>
                                        <img src={ellipse4Img} alt='' className='img-fluid' />
                                        <h5 className=''>Revolutionary AI Tutor</h5>
                                        <p className='copyText mb-0 '>Culminating in the most advanced AI Tutor. It understands you, cognitively, and guides you to mastery.  The most Personalized, Interactive, Engaging learning platform.</p>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div id="firstAccordian" name="firstAccordian" className='container-fluid py-5' >
                            <div className='container'>
                                <Accordion viewtestappdemoclick={(e) => this.opentestappdemovideo(e)} tryItOutClick={this.openDemoApp} isFirstOpen={true} accordionData={this.state.accordionSection1}></Accordion>
                            </div>
                        </div>

                        <div className='container-fluid py-5'>
                            <div className='container'>
                                <Accordion viewtestappdemoclick={(e) => this.opentestappdemovideo(e)} tryItOutClick={this.openDemoApp} isFirstOpen={true} accordionData={this.state.accordionSection2}></Accordion>
                            </div>
                        </div>

                        <div className='container-fluid py-5'>
                            <div className='container pb-5'>
                                <Accordion viewtestappdemoclick={(e) => this.opentestappdemovideo(e)} tryItOutClick={this.openDemoApp} isFirstOpen={true} accordionData={this.state.accordionSection3}></Accordion>
                            </div>
                        </div>

                        <div className='container-fluid py-5 bg-blue2'>
                            <div className='container' id='studentsContainer'>
                                <Row>
                                    <Col sm={12} className='text-center'>
                                        <h1 className='bannerTitle2 pt-3 pb-4 text-white'>What Our Students Say</h1>
                                    </Col>
                                    <Col sm={12} className=''>
                                        <Carousel dot={this.MyDot} cols={4} rows={1} gap={20} showDots={false} responsiveLayout={this.state.responsiveLayout}>
                                            {this.state.reviews.map(content => (
                                                <Carousel.Item key={content.name}>
                                                    <div className='studentBox2'>
                                                        {/* <img height={60} alt='image' src={content.image}></img> */}
                                                        <img src={quoteSignImg} alt='' className='img-fluid' />
                                                        <p className='pt-2 pb-4 copyText'>
                                                            {content.text}
                                                        </p>
                                                        <p className='text-right copyText text-muted'>- {content.name}</p>
                                                    </div>
                                                </Carousel.Item>
                                            ))
                                            }
                                        </Carousel>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div className='container-fluid bg-light-blue py-5 '>
                            <div className='container' >
                                <Row className="">

                                    {/* <Col xs={12} md={12} className=''>
                            <h2 className="bannerTitle2 text-center pb-4">Experience the Power of 1-on-1 Learning</h2>
                        </Col> */}
                                    <Col sm={12} lg={12} className='text-md-right text-center'>
                                        <video src={videoHomeBottom} width="100%" autoPlay loop muted preload='meta' playsinline ></video>
                                    </Col>

                                    {/* <Col sm={12} md={4} className='text-center'>
                            <div className='whiteBoxContainer'>
                                <div className='whiteBdrDiv'>
                                    <img src={mindDotsIcon} alt='' />
                                    <p className='boxText pt-4 '>Learn Faster, Without Struggle. With AI Tutor by your Side 24/7</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={4} className='text-center'>
                            <div className='whiteBoxContainer'>
                                <div className='whiteBdrDiv'>
                                    <img src={touchIcon} alt='' />
                                    <p className='boxText pt-4 '>Experience Interactive, Personalized, and Engaging Learning</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={4} className='text-center'>
                            <div className='whiteBoxContainer'>
                                <div className='whiteBdrDiv'>
                                    <img src={aPlusIcon2} alt='' />
                                    <p className='boxText pt-4 '>Ace Every Concept, Every Homework, Every Test</p>
                                </div>
                            </div>
                        </Col> */}
                                </Row>
                                {/* <div class="fbcontainer">
                        <div class="item" >
                            <div className='whiteBdrDiv'>
                                <img src={mindLinesIcon} alt='' />
                                <p className='boxText text-white'>Learn faster, without the struggle: Your AI Tutor is by your side Round The Clock</p>
                            </div>
                        </div>
                        <div class="item boxImg1" >

                        </div>
                        <div class="item" >
                            <div className='whiteBdrDiv'>
                                <img src={penMouseIcon} alt='' />
                                <p className='boxText text-white'>Experience aiPlato: Interactive, Personalized, And Engaging learning experience</p>
                            </div>
                        </div>
                        <div class="item boxImg2" >

                        </div>
                        <div class="item yellowBox " >
                            <div className='yellowBdrDiv'>
                                <img src={aPlusIcon} alt='' />
                                <p className='boxText '>Ace Every Homework, Every Test.</p>
                            </div>
                        </div>
                        <div class="item boxImg3" >

                        </div>

                    </div> */}
                                <Row className='py-5'>
                                    <Col sm={12} className='text-center'>
                                        <h1 className='bannerTitle pb-3'>Unleash Each Student's Potential <br className='d-none d-md-block' />With the most advanced AI Tutor for STEM</h1>
                                        <button
                                            onClick={this.openDemoApp}
                                            className="btnMain"
                                        >Try It Out</button>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                    </div>
                    <Modal style={{
                        position: 'absolute',
                        top: '100px',
                        left: '5px',
                        justify: 'center',
                        maxWidth: "400px"
                    }}
                        open={this.state.isOpen}
                        onClose={() => this.setState({ isOpen: false })}
                        aria-describedby="modal-modal-description"
                    >
                        <div className='infoModal'>
                            <h5>Optimized Experience Advisory</h5>
                            <img src={infoIcon}></img>
                            <p>We are actively working on making aiPlato available on all mobile and tablet devices. Currently we only support large screens.</p>
                        </div>

                    </Modal>


                    <Modal


                        open={this.state.showModalVideo}
                        onClose={this.closeVideoModal}
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >

                        <Fade in={this.state.showModalVideo}>
                            <Box sx={style}>
                                <div >
                                    <div className='videoDiv'>
                                        <video playsinline muted loop={true} autoPlay={true} width={showFullApp() ? "590" : "340"} height="300" controls>
                                            <source src={practiceTestDemoVideo} type="video/mp4" />
                                        </video>
                                    </div>

                                    <div className='modal-button text-center'>
                                        <Button className="videoClosebtn" onClick={this.closeVideoModal}> CLOSE </Button>
                                    </div>
                                </div>
                            </Box>
                        </Fade>

                    </Modal>




                    {/* <Modal className='modalVideo'
                        show={this.state.showModalVideo}
                        onHide={this.closeVideoModal}
                        draggable={false}
                        body={this.state.whiteBoardVideoDiv}
                        handleClose={this.closeVideoModal}
                        hideHeader={true}
                        showButton={false}
                    >
                    </Modal> */}

                    <TrapFocus open disableAutoFocus disableEnforceFocus>
                        <Fade appear={false} in={this.state.bannerOpen}>
                            <Paper
                                elevation={24}
                                role="dialog"
                                aria-modal="false"
                                aria-label="Cookie banner"
                                square
                                variant="outlined"
                                tabIndex={-1}
                                sx={{
                                    position: 'fixed',
                                    right: 0,
                                    m: 0,
                                    p: 2,
                                    borderWidth: 0,
                                    borderTopWidth: 1,
                                    zIndex: 9999,
                                    width: "30% !important",
                                    left: "30px",
                                    bottom: "20px",
                                    borderRadius: "10px",
                                    '@media (max-width: 600px)': {
                                        width: '100% !important',
                                        left: "0px !important"
                                    }
                                }}
                            >
                                <Stack
                                    direction={{ xs: 'column', sm: 'row' }}
                                    justifyContent="space-between"
                                    gap={2}
                                    sx={{ mb: 2 }}
                                >
                                    <Box

                                        sx={{
                                            flexShrink: 1,
                                            alignSelf: { xs: 'flex-start', sm: 'center' }
                                        }}
                                    >
                                        <Typography sx={{
                                            fontSize: "18px"
                                        }} fontWeight="bold">This website uses cookies.</Typography>
                                        <Typography sx={{
                                            fontSize: "12px"
                                        }}>This website uses cookies to provide you with a great experience and to help our website run effectively.
                                            By continuing to use aiPlato, you agree to <Button size="small" href="/termsandcondition" target='_blank'
                                                sx={{
                                                    // lineHeight: 0.50,
                                                    textTransform: "capitalize",
                                                    textDecoration: "underline",
                                                    color: "#000",
                                                    fontSize: "12px",
                                                    padding: "0px",

                                                }}>Terms of Use</Button> and <Button href="/privacypolicy" target='_blank' size="small" sx={{
                                                    // lineHeight: 0.50,
                                                    padding: "0px",
                                                    textTransform: "capitalize",
                                                    textDecoration: "underline",
                                                    color: "#000",
                                                    fontSize: "12px",

                                                }}>Privacy Policy</Button></Typography>


                                    </Box>
                                </Stack>
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="flex-end"
                                    spacing={2}
                                >
                                    <Button size="small" onClick={this.closeBanner} variant="outlined"
                                        sx={{

                                            textTransform: "capitalize",
                                            borderColor: "#000",
                                            color: "#000"
                                        }}>
                                        Dismiss
                                    </Button>

                                </Stack>

                            </Paper>
                        </Fade>
                    </TrapFocus>

                </>

                :
                // <div className='fwidth'>
                //     <div className="homecontmpad container-fluid">
                //         <Container className="">
                //             <div className="col-12 col-md-12 col-lg-12">
                //                 <div className='homeBannerHead'><h1 className='text-white'>A Stealth Mode Startup</h1></div>
                //                 <p className='text-white pt-2 bannerText' style={{ margin: '0' }}>Democratizing 1-on-1 Teaching.</p>
                //                 <p className='text-white bannerText'>Reinventing STEM Education.</p>
                //                 <div className="input-group p-0 ">
                //                     <form className="d-md-flex">
                //                         {
                //                             this.state.shoowearlyaccess ? (
                //                                 <div>
                //                                     <button
                //                                         onClick={this.openDemoApp}
                //                                         className="btn btn-white mt-2"
                //                                     >Try It Out</button>
                //                                     <button
                //                                         onClick={this.showemailhandler}
                //                                         className="btn btn-white ml-2 mt-2"
                //                                     >Get Early Access</button>
                //                                 </div>

                //                             ) : null
                //                         }
                //                         {
                //                             this.state.showemaildiv ? (
                //                                 <>
                //                                     <div class="form-group mgbtmzero mright15 mrightzero">
                //                                         <input
                //                                             autoFocus
                //                                             type="email"
                //                                             className="form-control mr-2 inp mobinpst "
                //                                             value={this.state.email}
                //                                             onChange={this.handleEmailChange}
                //                                             placeholder="Enter your email address" />
                //                                     </div>
                //                                     {this.state.showPassword ? <><div class="form-group mgbtmzero mright15 mrightzero">
                //                                         <input
                //                                             type="password"
                //                                             className="form-control mr-2 inp mobinpst "
                //                                             value={this.state.password}
                //                                             onChange={(e) => this.setState({ password: e.target.value })}
                //                                             placeholder="Enter your password" />
                //                                     </div>

                //                                     </> : null}

                //                                     {this.state.showdropdown ? <><div class="form-group mgbtmzero mright15 mrightzero">
                //                                         <select onChange={this.handlestudenttutorchange} id="studenttutor" name="studenttutor" className="form-control mr-2 inp mobinpst ">
                //                                             <option value="Student" selected>I am a Student</option>
                //                                             <option value="Educator" >I am an Educator</option>


                //                                         </select>
                //                                     </div>

                //                                     </> : null}

                //                                     <button
                //                                         onClick={this.getEarlyAccessHandler}
                //                                         disabled={!this.hasValidValues()}
                //                                         className="btn btn-primary formbtnget"
                //                                         type="submit">{this.state.buttontext}</button>

                //                                 </>
                //                             ) : null
                //                         }

                //                     </form>
                //                 </div>
                //             </div>
                //         </Container>
                //     </div>
                //     <div className='container-fluid py-5 bg-light-blue'>
                //         <div className='container'>
                //             <Row className='ellipseSection '>
                //                 <Col sm={12} md={3} className='banner2Text'>
                //                     <img src={ellipse1Img} alt='' className='img-fluid' />
                //                     <h5>Deep Research in Education</h5>
                //                     <p className='copyText mb-0'>aiPlato Harvard and Stanford education researchers have created groundbreaking insights on how expert 1-on-1 tutors boost learning.</p>
                //                 </Col>
                //                 <Col sm={12} md={1} className='text-center'><img src={plusLightBlueImg} alt='' className='img-fluid blackArrowCircle' /></Col>
                //                 <Col sm={12} md={3} className='banner2Text'>
                //                     <img src={ellipse2Img} alt='' className='img-fluid' />
                //                     <h5>Purpose-Built Gen AI</h5>
                //                     <p className='copyText mb-0'>aiPlato AI experts have meticulously embodied   insights from education research in purpose-built AI.</p>
                //                 </Col>
                //                 <Col sm={12} md={1} className='text-center'><img src={equalLightBlueImg} alt='' className='img-fluid blackEqualCircle' /></Col>
                //                 <Col sm={12} md={3} className='banner2Text'>
                //                     <img src={ellipse4Img} alt='' className='img-fluid' />
                //                     <h5>Revolutionary AI Tutor</h5>
                //                     <p className='copyText mb-0'>Culminating in the most advanced AI Tutor. It understands you, cognitively, and guides you to mastery.  The most Personalized, Interactive, Engaging learning platform.</p>
                //                 </Col>
                //             </Row>
                //         </div>
                //     </div>

                //     <div className='container-fluid bg-white py-4'>
                //         <Container className="py-5">
                //             <Row >
                //                 <Col xs={12} xl={8} className='' >
                //                     <img src={whoIsiaPlatoImg} alt='' className='img-fluid' />
                //                 </Col>
                //                 <Col xs={12} xl={4} className='text-blue pt-3 pt-xl-0' >
                //                     <h2 className="bannerTitle2 text-center">Who is aiPlato?</h2>
                //                     <hr className='hrBlueLight' />
                //                     <p className='copyText'>We are a team of Harvard/Stanford educators, AI experts and entrepreneurial engineers who passionately believe in the cause of making 1-on-1 Tutoring accessible to millions.</p>
                //                     <hr className='hrBlueLight' />
                //                     <p className='copyText'>We believe every student deserves to unleash their full potential. We believe that every student deserves access to the most powerful learning tool, 1-on-1 Teaching, that is proven to boost performance by 2 standard deviations.</p>
                //                     <hr className='hrBlueLight' />
                //                     <p className='copyText'>We have built the most advanced purpose-built Gen AI for 1-on-1 teaching, embodying groundbreaking insights from a decade of education research at Harvard and Stanford.</p>
                //                 </Col>
                //             </Row>
                //         </Container>
                //     </div>
                //     <div className='container-fluid bg-light-blue'>
                //         <Container className="secpad">
                //             <Row className="">

                //                 <Col xs={12} md={12} className=''>
                //                     <h2 className="bannerTitle2 text-center pb-4">Experience the Power of 1-on-1 Learning</h2>
                //                 </Col>
                //                 <Col sm={12} md={4} className='text-center'>
                //                     <div className='whiteBoxContainer'>
                //                         <div className='whiteBdrDiv'>
                //                             <img src={mindDotsIcon} alt='' />
                //                             <p className='boxText pt-4 '>Learn Faster, Without Struggle. With AI Tutor by your Side 24/7</p>
                //                         </div>
                //                     </div>
                //                 </Col>
                //                 <Col sm={12} md={4} className='text-center'>
                //                     <div className='whiteBoxContainer'>
                //                         <div className='whiteBdrDiv'>
                //                             <img src={touchIcon} alt='' />
                //                             <p className='boxText pt-4 '>Experience Interactive, Personalized, and Engaging Learning</p>
                //                         </div>
                //                     </div>
                //                 </Col>
                //                 <Col sm={12} md={4} className='text-center'>
                //                     <div className='whiteBoxContainer'>
                //                         <div className='whiteBdrDiv'>
                //                             <img src={aPlusIcon2} alt='' />
                //                             <p className='boxText pt-4 '>Ace Every Concept, Every Homework, Every Test</p>
                //                         </div>
                //                     </div>
                //                 </Col>
                //             </Row>
                //             <Row className="py-5 text-center">
                //                 <Col xs={12} md={12}>
                //                     <h2 className="bannerTitle2 py-4">Unleash Each Student's Potential<br />With the Most Advanced AI Tutor for STEM</h2>                                  <button
                //                         onClick={this.openDemoApp}
                //                         className="btn btn-white shadow1"
                //                     >Try It Out</button>
                //                 </Col>
                //             </Row>
                //         </Container>
                //     </div>

                //     <div className='container-fluid py-5 bg-blue2'>
                //         <div className='container' id='studentsContainer'>
                //             <Row>
                //                 <Col sm={12} className='text-center'>
                //                     <h1 className='bannerTitle2 text-white pt-3 '>What Our Students Say</h1>
                //                     <p className='text-white whiteBgText'>*Pilots are in progress at two of the top twenty U.S. universities and several high schools.</p>
                //                 </Col>
                //                 <Col sm={12} className='pt-5'>
                //                     <Carousel dot={this.MyDot} cols={4} rows={1} gap={20} showDots={false} responsiveLayout={this.state.responsiveLayout}>
                //                         {this.state.reviews.map(content => (
                //                             <Carousel.Item key={content.name}>
                //                                 <div className='studentBox2 border-0'>
                //                                     {/* <img height={60} alt='image' src={content.image}></img> */}
                //                                     <img src={quoteSignImg} alt='' className='img-fluid' />
                //                                     <p className='pt-2 pb-4 copyText'>
                //                                         {content.text}
                //                                     </p>
                //                                     <p className='text-right copyText text-primary'>- {content.name}</p>
                //                                 </div>
                //                             </Carousel.Item>
                //                         ))
                //                         }
                //                     </Carousel>
                //                 </Col>
                //             </Row>
                //         </div>
                //     </div>
                // </div>
                null
        )
    }
}

export default Home;    