import React, { Component } from 'react';
import { Row, Col, Container } from "react-bootstrap"
import { checkCredentialsAPI, validIds, saveToDbAPI } from '../../common/API'
import { toast } from 'react-toastify';
import secimg from "../../assets/images/secimg.svg"
import Cookies from 'universal-cookie';
import './Teacher.scss';
import img1 from "../../assets/images/1.jpg"
import img2 from "../../assets/images/2.jpg"
import img3 from "../../assets/images/3.jpg"
import tutorimg1 from '../../assets/images/tutorimg1.png';
import tutorimg2 from '../../assets/images/tutoringimg2.png';
import { APP_URL, WEBSITE_URL, getIsPrivate } from '../../common/Functions';
import { withRouter } from 'react-router-dom';


class Teacher extends Component {
    state = {
        email: '',
        password: '',
        validIds: [],
        showPassword: false,
        shoowearlyaccess: true,
        showemaildiv: false,
        buttontext: "Request Demo",
        isPrivate: false,
    }

    componentWillMount() {
        this.setState({ isPrivate: getIsPrivate() })
    }

    componentDidMount() {
        this.fetchIds()
    }

    handleEmailChange = (event) => {
        let email = event.target.value.trim()
        this.setState({ email: email });
        if (this.state.validIds.includes(email.toLowerCase())) {
            this.setState({ showPassword: true, buttontext: "Login" });
        } else {
            this.setState({ showPassword: false });
        }
    }

    isValidEmail = (email) => {
        return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    }

    getEarlyAccessHandler = (e) => {
        e.preventDefault()
        if (this.state.password.length > 0) {
            let data = { 'email': this.state.email.trim().toLowerCase(), password: this.state.password }

            checkCredentialsAPI(data).then(res => {
                console.log(res)
                if (res.status === 200) {
                    if (res.data['found'] === true) {
                        this.addValidationCookie(this.state.email.trim().toLowerCase(),
                            res.data.userId,
                            res.data.role,
                            res.data.name,
                            res.data.status,
                            res.data.tryThisPinsEnabled,
                            res.data.QATestFlag,
                            this.convertDurationToSeonds(res.data.timeLimit),
                            res.data.institute_id,
                            res.data.user_timezone)
                        toast.success("Valid Demo Credentials!", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        window.open(APP_URL, '_self')
                        this.setState({ email: '', password: '', showPassword: false });
                        this.setState({ shoowearlyaccess: true, showemaildiv: false, buttontext: "Request Access" })
                    }
                    else if (res.data['isexpired'] === true) {
                        toast.error("Your account is expired, please get in touch with support team!", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        this.setState({ shoowearlyaccess: true, showemaildiv: false })
                    }
                    else {
                        toast.error("Please enter valid email id and password.", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        this.setState({ shoowearlyaccess: false, showemaildiv: true })
                    }

                } else {
                    toast.error("Server Error !", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            }).catch(err => {
                console.error(err.message)
                toast.error("Please enter valid email id and password.", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
        } else {
            // save email to db
            let data = { 'email': this.state.email.trim().toLowerCase(), studenteducatior: "Educator" }

            saveToDbAPI(data).then(res => {
                toast.success("Thank you for submitting your early access request. Our team will be in touch with you shortly to provide further information and support.", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                // this.setState({ email: '' });
                // this.setState({ shoowearlyaccess: true, showemaildiv: false })

                window.open(`${WEBSITE_URL}earlyaccesspriorityinfo/${res.data.data}`)

            }).catch(err => {
                console.error(err.message)
                toast.warning("We already have your email in our system, someone from our team contact you soon.", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                this.setState({ email: '' });
            })
        }
    }

    addValidationCookie = (email, userId, role, name, status, tryThisPinsEnabled, QATestFlag, timeLimit, institute_id, usertimezone) => {
        const cookies = new Cookies();
        cookies.set('isValid', 'yes', { path: '/', domain: window.location.hostname, maxAge: timeLimit });
        cookies.set('email', email, { path: '/', domain: window.location.hostname, maxAge: timeLimit });
        cookies.set('userId', userId, { path: '/', domain: window.location.hostname, maxAge: timeLimit });
        cookies.set('name', name, { path: '/', domain: window.location.hostname, maxAge: timeLimit });
        cookies.set('role', role, { path: '/', domain: window.location.hostname, maxAge: timeLimit });
        cookies.set('status', status, { path: '/', domain: window.location.hostname, maxAge: timeLimit });
        cookies.set('tryThisPinsEnabled', tryThisPinsEnabled, { path: '/', domain: window.location.hostname, maxAge: timeLimit });
        cookies.set('QATestFlag', QATestFlag, { path: '/', domain: window.location.hostname, maxAge: timeLimit });
        cookies.set('institute_id', institute_id, { path: '/', domain: window.location.hostname, maxAge: timeLimit });
        cookies.set('usertimezone', usertimezone, { path: '/', domain: window.location.hostname, maxAge: timeLimit });
        console.log('cookies are ', cookies.get('isValid'), window.location.hostname);
    }

    fetchIds = () => {

        validIds().then(res => {
            this.setState({ validIds: res.data.ids.map(item => atob(item)) });
        }).catch(err => {
            console.error(err.message)
            toast.error("Error fetching ids!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })

    }

    hasValidValues = () => {
        return this.state.email.trim().length > 0
            && this.isValidEmail(this.state.email.trim())
    }

    convertDurationToSeonds = (duration) => {
        const a = duration.split(':');
        return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    }
    showemailhandler = () => {

        this.setState({ shoowearlyaccess: false, showemaildiv: true })
    }

    navigateToSignUp = () => {
        const { history } = this.props;
        history.push('/signup?c3VtbWl0PXRydWU=');
    }

    render() {
        return (
            this.state.isPrivate ?

                <div className='fwidth'>
                    <div className="banner-pt container-fluid bg-gray1">
                        <Container className="gradientBannerBg ">
                            <div className="col-12 col-md-12 col-lg-12 d-flex align-items-center">
                                <div className='fwidth'>
                                    <div className='homeBannerHead1'>
                                        <h1>STEM AI Teaching Assistant</h1>
                                        <h3>Increase Student Learning, Reduce Your Workload</h3>
                                    </div>
                                    {/* <h4 className="tcenter mgbtm18 fontsstle">Stealth Mode Startup</h4> */}

                                    <div className="input-group p-0 pt-3 text-center">
                                        <form className="d-flex justify-content-center">
                                            {
                                                this.state.shoowearlyaccess ? (
                                                    <button
                                                        onClick={this.showemailhandler}
                                                        className="btnMain"
                                                    >Request a Demo</button>

                                                ) : null
                                            }
                                            <button
                                                onClick={this.navigateToSignUp}
                                                title='For Free Access to Your Students'
                                                className="btnMain" style={{ marginLeft: '10px' }}
                                            >Sign up for Teachers</button>
                                            {
                                                this.state.showemaildiv ? (

                                                    <>
                                                        <div class="form-group mgbtmzero mright15 mrightzero">
                                                            <input
                                                                autoFocus
                                                                type="email"
                                                                className="form-control mr-2 inp mobinpst "
                                                                value={this.state.email}
                                                                onChange={this.handleEmailChange}
                                                                placeholder="Enter your email address" />
                                                        </div>
                                                        {this.state.showPassword ? <div class="form-group mgbtmzero mright15 mrightzero">
                                                            <input
                                                                type="password"
                                                                className="form-control mr-2 inp mobinpst "
                                                                value={this.state.password}
                                                                onChange={(e) => this.setState({ password: e.target.value })}
                                                                placeholder="Enter your password" />
                                                        </div> : null}
                                                        <button
                                                            onClick={this.getEarlyAccessHandler}
                                                            disabled={!this.hasValidValues()}
                                                            className="btn btn-primary formbtnget"
                                                            type="submit">{this.state.buttontext}</button>
                                                    </>
                                                ) : null
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>

                    {/* <div className="bgpat prelative">
                    <Container className="homecontmpad">
                        <div className="col-12 col-md-12 col-lg-12 d-flex align-items-center">
                            <div className='fwidth'>
                                <div className='display-1 tcenter'>aiPlato</div>
                               
                                <h5><div className='tcenter mgbtm25 subHeader'>Democratizing 1-on-1 Teaching</div></h5>
                                <div className="input-group">
                                    <form className="ctflex jcenter flcplm">
                                        {
                                            this.state.shoowearlyaccess ? (
                                                <button
                                                    onClick={this.showemailhandler}
                                                    className="btn btn-primary formbtnget"
                                                >Get Early Access as an Educator</button>

                                            ) : null
                                        }
                                        {
                                            this.state.showemaildiv ? (

                                                <>
                                                    <div class="form-group mgbtmzero mright15 mrightzero">
                                                        <input
                                                        autoFocus
                                                            type="email"
                                                            className="form-control mr-2 inp mobinpst "
                                                            value={this.state.email}
                                                            onChange={this.handleEmailChange}
                                                            placeholder="Enter your email address" />
                                                    </div>
                                                    {this.state.showPassword ? <div class="form-group mgbtmzero mright15 mrightzero">
                                                        <input
                                                            type="password"
                                                            className="form-control mr-2 inp mobinpst "
                                                            value={this.state.password}
                                                            onChange={(e) => this.setState({ password: e.target.value })}
                                                            placeholder="Enter your password" />
                                                    </div> : null}
                                                    <button
                                                        onClick={this.getEarlyAccessHandler}
                                                        disabled={!this.hasValidValues()}
                                                        className="btn btn-primary formbtnget"
                                                        type="submit">{this.state.buttontext}</button>
                                                </>
                                            ) : null
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div> */}
                    <div className='container-fluid bg-gray1'>
                        <Container className="secpad">
                            <Row className="">
                                <Col xs={12} md={12}>
                                    <p className='copytext'>Discover how aiPlato's AI Teaching Assistant is redefining the educational experience. Our platform not only boosts student performance but also alleviates educators' grading burdens with intelligent automation.</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className='container-fluid bg-white'>
                        <Container className="secpad">
                            <Row className="">
                                <Col xs={12} md={12}>
                                    <h2 className="mgbtm25 subheadText">Application highlights</h2>
                                    <ul className='copyText'>
                                        <li><h5 className='subheadText2'>Tutoring Helper</h5>
                                            Our AI seamlessly extends your teaching capacity, providing students with personalized guidance and support, just like a human tutor. </li>
                                        <br></br>
                                        <img width={500} src={tutorimg1} alt='' className='img-fluid' />
                                        <br></br><br></br>
                                        <li><h5 className='subheadText2' >Online Homework and Test Prep :</h5>
                                            aiPlato revolutionizes study sessions with an advanced autograding system that delivers instant, detailed assessments of student work. Through interactive exercises and immediate feedback, students master STEM concepts at their own pace, while educators gain valuable insights into areas of student mastery and struggle. </li>
                                        <br></br>
                                        <li><h5 className='subheadText2' >Proficiency Map and Resolution Aid :</h5>
                                            Gain insightful overviews of student performance with our Proficiency Maps, and offer targeted interventions to swiftly address learning gaps. </li>
                                        <br></br>
                                        <img width={500} src={tutorimg2} alt='' className='img-fluid' />
                                        <br></br><br></br>
                                        <li><h5 className='subheadText2' >In-Class Engagement :</h5>  In-Class Assistant for Real-time Q&A. A powerful teaching assistant during class, allowing you to answer student questions, all at once! </li>
                                    </ul>
                                    <br></br>
                                    <h5>Take the next step in educational innovation. Simplify your teaching workflow and enhance learning outcomes with aiPlato.</h5>
                                    <div className="input-group p-0 pt-3 text-left">
                                        <form className="">
                                            {
                                                this.state.shoowearlyaccess ? (
                                                    <button
                                                        onClick={this.showemailhandler}
                                                        className="btnMain"
                                                    >Request a Demo</button>

                                                ) : null
                                            }
                                            {
                                                this.state.showemaildiv ? (

                                                    <>
                                                        <div class="form-group mgbtmzero mright15 mrightzero">
                                                            <input
                                                                autoFocus
                                                                type="email"
                                                                className="form-control mr-2 inp mobinpst "
                                                                value={this.state.email}
                                                                onChange={this.handleEmailChange}
                                                                placeholder="Enter your email address" />
                                                        </div>
                                                        {this.state.showPassword ? <div class="form-group mgbtmzero mright15 mrightzero">
                                                            <input
                                                                type="password"
                                                                className="form-control mr-2 inp mobinpst "
                                                                value={this.state.password}
                                                                onChange={(e) => this.setState({ password: e.target.value })}
                                                                placeholder="Enter your password" />
                                                        </div> : null}
                                                        <button
                                                            onClick={this.getEarlyAccessHandler}
                                                            disabled={!this.hasValidValues()}
                                                            className="btn btn-primary formbtnget"
                                                            type="submit">{this.state.buttontext}</button>
                                                    </>
                                                ) : null
                                            }
                                        </form>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className='container-fluid bg-gray1'>
                        <Container className="secpad">
                            <Row className="">
                                <Col xs={12} md={12}>
                                    <p className='copytext'>With aiPlato, education goes beyond delivering content—it's about creating a seamless learning ecosystem. Empower your students with AI-driven support and take advantage of our sophisticated autograding to free your schedule and focus on what you do best—teaching. Embrace the future with aiPlato, your partner in educational excellence.</p>
                                </Col>

                            </Row>
                        </Container>
                    </div>
                    {/* <Container className=""> }
                    <Row className="">
                        <Col xs={12} md={12}>
                            <hr />
                        </Col>
                    </Row>
                </Container>
                <Container className="secpad">
                    <Row className="teacherContentImg">
                        <Col xs={12} md={6}>
                            <img src={img2} />

                        </Col>
                        <Col xs={12} md={6}>
                            <h2 className="oftitle mgbtm25">Interactive learning <br />experience by aiPlato</h2>
                            <p className="">The time has come to provide every student with an interactive learning experience equivalent to one-on-one teaching – personalized to their cognitive processing in real-time. The time has come for online education to help each student overcome the obstacles in acing every problem - watching every error, diagnosing the root cause, and reinforcing knowledge-gaps, like a one-on-one teacher.  21st century education must stimulate curiosity. We must turn education from a chore that students dread into a game-like experience that they enjoy.  And yes, human tutoring must be accessible to every student, assisted by AI.</p>
                        </Col>
                    </Row>
                </Container>
                <Container className="">
                    <Row className="">
                        <Col xs={12} md={12}>
                            <hr />
                        </Col>
                    </Row>
                </Container>
                <Container className="secpad">
                    <Row className="teacherContentImg">
                        <Col xs={12} md={6}>
                            <h2 className="oftitle mgbtm25">Interactive learning <br />experience by aiPlato</h2>
                            <p className="">The time has come to provide every student with an interactive learning experience equivalent to one-on-one teaching – personalized to their cognitive processing in real-time. The time has come for online education to help each student overcome the obstacles in acing every problem - watching every error, diagnosing the root cause, and reinforcing knowledge-gaps, like a one-on-one teacher.  21st century education must stimulate curiosity. We must turn education from a chore that students dread into a game-like experience that they enjoy.  And yes, human tutoring must be accessible to every student, assisted by AI.</p>
                        </Col>
                        <Col xs={12} md={6}>
                            <img src={img3} />
                        </Col>
                    </Row>
                </Container> */}
                </div>

                :

                null
        )
    }
}
export default withRouter(Teacher);