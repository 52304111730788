import React, { Component } from 'react';
import { Row, Col, Container } from "react-bootstrap";
import './career.scss';
import Cookies from 'universal-cookie';
import { getIsPrivate } from '../../common/Functions';

class Career extends Component {

    state = {
        isPrivate: false,
    }

    componentWillMount() {
        this.setState({ isPrivate: getIsPrivate() })
    }

    render() {
        const contentAuthorClickScroll = () => {
            const element = document.getElementById('contentAuthor-section');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        };
        const mlNlpClickScroll = () => {
            const element = document.getElementById('mlNlp-section');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        };

        const fullstackClickScroll = () => {
            const element = document.getElementById('fullstack-section');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }


        return (
            this.state.isPrivate ?
                <div className='fwidth'>
                    <div className="banner-pt container-fluid bg-gray1">
                        <Container className="gradientBannerBg ">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className='homeBannerHead1'>
                                    <h1>Current Openings</h1>
                                    <p className='copyText pt-3'>Unlock Your Potential with Us: Discover Rewarding <br />Careers in Education and AI</p>
                                </div>
                            </div>
                        </Container>
                        <Container className='py-5'>
                            <div className="pb-3 inlineHeigth copyText">aiPlato offers an intellectually thriving environment with a stellar team of educators from Harvard/Stanford, AI experts from Silicon Valley giants, and entrepreneurial engineers.</div>

                            <div className=''>
                                <button onClick={contentAuthorClickScroll} className='btnBlack'>Content Authors</button>
                                <button onClick={fullstackClickScroll} className='btnBlack mx-2'>Full Stack Developer</button>
                                <button onClick={mlNlpClickScroll} className='btnBlack'>ML & NLP Engineer</button>
                            </div>
                        </Container>
                    </div>
                    <div className='container-fluid bg-white py-5'>
                        <Container>
                            <div className="subheadText">Content Authors: Physics Teachers</div>
                                    
                                    <div id="contentAuthor-section" className="pb-3 inlineHeigth copyText">We are looking for Curriculum Content Author, Physics. </div>
                                    <div >

                                        <div className="font-weight-bold">What You'll Do</div>
                                        <ul className='copyText'>
                                            <li>Develop content for the AP physics curriculum for the Artificial Intelligence (AI)-driven 1-on-1 tutoring platform. This AI is built upon years of research and modeling of expert tutoring. </li>
                                            <li>Help AI develop the Knowledge Graph of physics and enrich concepts in the Knowledge Graph with curated content and exercise problems.</li>
                                            <li>Contribute to the guidelines for other professors/teachers and content authors/developers.</li>
                                            <li>Review content generated by AI and by peers.</li>
                                            <li>Participate in ongoing research on data-informed, innovative educational approaches, and their impact on learning outcomes.</li>
                                            <li>Contribute to extending the emerging insights on AI models for education to STEM subjects beyond physics.</li>
                                        </ul>

                                    </div>
                                    <div>

                                        <div className="font-weight-bold">What Will Make You a Great Fit:</div>
                                        <ul className='copyText'>
                                            <li>Deep knowledge of curriculum for AP Physics C (Mech and E&M), AP Physics 1 & 2</li>
                                            <li>Classroom and online teaching experience - college or high-school level</li>
                                            <li>Experience with helping students 1-on-1</li>
                                            <li>Genuine interest in transforming education, by scaling 1-on-1 teaching to reach millions</li>
                                            <li>Openness to innovative ideas – on education, and on technology (Artificial Intelligence)</li>
                                            <li>Openness to blend teacher’s intuition with data-informed pedagogical techniques</li>
                                            <li>Aptitude for seeking best-of-web physics simulations, videos and other resources</li>

                                        </ul>

                                    </div>
                        </Container>
                    </div>
                    <div className='container-fluid bg-gray1 py-5'>
                        <Container>
                            <div className="subheadText">Full Stack Developer</div>
                            <br />
                            <div id="fullstack-section" className="pb-3 inlineHeigth copyText">We are looking for exceptional engineers, with full-stack, front-end or back-end expertise, to take on the challenge of engineering a high performing, scalable, web app.  Interest in AI/ML a plus. Interest in revolutionizing education a big plus.</div>
                            <div>

                                <div className="font-weight-bold">What You'll Do</div>
                                <ul className='copyText'>
                                    <li>Develop front-end and/or back-end, using modern tools and tech stack, to engineer a high performing, scalable, responsive web app</li>
                                    <li>Collaborate with AI/ML scientists to engineer AI/ML capabilities </li>
                                    <li>Partner with UX designers to deliver a captivating user experience </li>
                                    <li>Partner with product management on roadmap planning and execution</li>
                                    <li>Elevate best practices for efficient and high-quality software development</li>
                                </ul>

                            </div>
                            <div className="font-weight-bold">What Will Make You a Great Fit:</div>
                                        <ul className='copyText'>
                                            <li>Adept at understanding complex concepts and converting them into clean solutions</li>
                                            <li>BS/MS degree in Computer Science or equivalent </li>
                                            <li>Strong foundation of object oriented design, design patterns and Java coding principles</li>
                                            <li>4+ years of hands-on experience developing complex, large-scale web apps</li>
                                            <li>Expertise in HTML, CSS, JavaScript, and experience using web frameworks & libraries such as React (preferred) or Angular</li>
                                            <li>Expertise in Java (preferred) or Python for back-end</li>
                                            <li>Experience in developing REST web services</li>
                                            <li>Knowledge of SQL and NoSQL DBs, e.g. MongoDB</li>
                                            <li>Exposure to microservices architecture</li>
                                            <li>Exposure to container technologies e.g. Docker, Kubernetes</li>
                                            <li>Exposure to cloud platforms - AWS/GCP/Azure</li>
                                            <li>Exposure to AI/ML technologies a plus</li>
                                        </ul>
                        </Container>
                    </div>
                    <div className='container-fluid py-5'>
                        <Container>
                        <div className="subheadText">ML & NLP Engineer</div>
                                    <br />
                                    <div id='mlNlp-section' className="pb-3 inlineHeigth copyText">We are looking for exceptional engineering talent, with ML and NLP experience. The rewards include an attractive salary, bonus, and stock options in a rapidly growing startup.</div>
                                    <div>

                                        <div className="font-weight-bold">What Will Make You a Great Fit:</div>
                                        <ul className='copyText'>
                                            <li>Adept at understanding complex concepts and converting them into clean solutions</li>
                                            <li>BS/MS degree in Computer Science or equivalent </li>
                                            <li>Experience with working with one or more general purpose languages, at production level: Python, Java, Scala, C/C++.</li>
                                            <li>Experience working with ML/DL libraries like sklearn, Keras, pytorch, tensorflow.</li>
                                            <li>Experience working with large distributed frameworks like Spark.</li>
                                            <li>Demonstrated ability to build, test, deploy ML models into production (please send related github, if possible), into cloud infrastructure.</li>
                                            <li>Experience with constructing/managing ML pipelines and/or data pipelines.</li>
                                            <li>Experience with monitoring of ML models post deployment. Model observability, performance monitoring, alerting and other related MLOps tasks.</li>
                                            <li>Experience working with LLMs like Bert/GPT3.5/Huggingface language models for NLP tasks a plus</li>
                                            <li>Experience with Knowledge Graph a plus</li>
                                            <li>Experience with prompt engineering a plus</li>
                                        </ul>

                                    </div>
                                    </Container>
                    </div>
                    


                </div>
                :
                <div className='fwidth'>
                    <div className="homecontmpad container-fluid">
                        <Container className="">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className='homeBannerHead text-white'>Current Openings <br />
                                    <h1 style={{ fontSize: "20px", letterSpacing: "0.5px", paddingTop: "20px", color:"#ffffff" }}>Unlock Your Potential with Us: Discover Rewarding Careers in Education and AI</h1>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <Container className="secpad">
                        <Row className="careerContainer">
                            <Col xs={12} md={12}>

                                <div className=''>
                                    {/* <div className="font-weight-bold">About aiPlato</div>
                            <div className="pb-3 inlineHeigth">aiPlato, Inc. (aiPlato.ai) is a stealth mode startup with a mission to reinvent K-12 education with AI-powered 1-on-1 Tutoring.</div> */}
                                    <div className="pb-3 inlineHeigth copyText">aiPlato offers an intellectually thriving environment with a stellar team of educators from Harvard/Stanford, AI experts from Silicon Valley giants, and entrepreneurial engineers.</div>

                                    <div className='careerLinks'>
                                        <button onClick={contentAuthorClickScroll}>Content Authors</button>
                                        <button onClick={fullstackClickScroll}>Full Stack Developer</button>
                                        <button onClick={mlNlpClickScroll}>ML & NLP Engineer</button>
                                    </div>
                                    <hr></hr>
                                    <div className="font-weight-bold font-quincy">Content Authors/Curators: Physics Teachers </div>
                                    <br />
                                    <div id="contentAuthor-section" className="pb-3 inlineHeigth copyText">We are looking for Curriculum Content Author, Physics. </div>
                                    <div >

                                        <div className="font-weight-bold font-quincy">What You'll Do</div>
                                        <ul className='copyText'>
                                            <li>Develop content for the AP physics curriculum for the Artificial Intelligence (AI)-driven 1-on-1 tutoring platform. This AI is built upon years of research and modeling of expert tutoring. </li>
                                            <li>Collaborate with our AI experts to train AI in Physics Concepts by curating content and exercise problems in Knowledge Graph representation.</li>
                                            <li>Help AI develop the Knowledge Graph of physics and enrich concepts in the Knowledge Graph with curated content and exercise problems.</li>
                                            <li>Contribute to the guidelines for other professors/teachers and content authors/developers.</li>
                                            <li>Review content generated by AI and by peers.</li>
                                            <li>Participate in ongoing research on data-informed, innovative educational approaches, and their impact on learning outcomes.</li>
                                            <li>Contribute to extending the emerging insights on AI models for education to STEM subjects beyond physics.</li>
                                        </ul>

                                    </div>
                                    <div>

                                        <div className="font-weight-bold font-quincy">What Will Make You a Great Fit:</div>
                                        <ul className='copyText'>
                                            <li>Deep knowledge of curriculum for AP Physics C (Mech and E&M), AP Physics 1 & 2</li>
                                            <li>Classroom and online teaching experience - college or high-school level</li>
                                            <li>Experience with helping students 1-on-1</li>
                                            <li>Genuine interest in transforming education, by scaling 1-on-1 teaching to reach millions</li>
                                            <li>Openness to innovative ideas – on education, and on technology (Artificial Intelligence)</li>
                                            <li>Openness to blend teacher’s intuition with data-informed pedagogical techniques</li>
                                            <li>Aptitude for seeking best-of-web physics simulations, videos and other resources</li>

                                        </ul>

                                    </div>
                                    <hr className='hrclass'></hr>
                                    <div className="font-weight-bold font-quincy">Full Stack Developer</div>
                                    <br />
                                    <div id="fullstack-section" className="pb-3 inlineHeigth copyText">We are looking for exceptional engineers, with full-stack, front-end or back-end expertise, to take on the challenge of engineering a high performing, scalable, web app.  Interest in AI/ML a plus. Interest in revolutionizing education a big plus.</div>
                                    <div>

                                        <div className="font-weight-bold font-quincy">What You'll Do</div>
                                        <ul className='copyText'>
                                            <li>Develop front-end and/or back-end, using modern tools and tech stack, to engineer a high performing, scalable, responsive web app</li>
                                            <li>Collaborate with AI/ML scientists to engineer AI/ML capabilities </li>
                                            <li>Partner with UX designers to deliver a captivating user experience </li>
                                            <li>Partner with product management on roadmap planning and execution</li>
                                            <li>Elevate best practices for efficient and high-quality software development</li>
                                        </ul>

                                    </div>

                                    <div>

                                        <div className="font-weight-bold font-quincy">What Will Make You a Great Fit:</div>
                                        <ul className='copyText'>
                                            <li>Adept at understanding complex concepts and converting them into clean solutions</li>
                                            <li>BS/MS degree in Computer Science or equivalent </li>
                                            <li>Strong foundation of object oriented design, design patterns and Java coding principles</li>
                                            <li>4+ years of hands-on experience developing complex, large-scale web apps</li>
                                            <li>Expertise in HTML, CSS, JavaScript, and experience using web frameworks & libraries such as React (preferred) or Angular</li>
                                            <li>Expertise in Java (preferred) or Python for back-end</li>
                                            <li>Experience in developing REST web services</li>
                                            <li>Knowledge of SQL and NoSQL DBs, e.g. MongoDB</li>
                                            <li>Exposure to microservices architecture</li>
                                            <li>Exposure to container technologies e.g. Docker, Kubernetes</li>
                                            <li>Exposure to cloud platforms - AWS/GCP/Azure</li>
                                            <li>Exposure to AI/ML technologies a plus</li>
                                        </ul>

                                    </div>
                                    <hr></hr>
                                    <div className="font-weight-bold font-quincy">ML & NLP Engineer</div>
                                    <br />
                                    <div id='mlNlp-section' className="pb-3 inlineHeigth copyText">We are looking for exceptional engineering talent, with ML and NLP experience. The rewards include an attractive salary, bonus, and stock options in a rapidly growing startup.</div>
                                    <div>

                                        <div className="font-weight-bold font-quincy">What Will Make You a Great Fit:</div>
                                        <ul className='copyText'>
                                            <li>Adept at understanding complex concepts and converting them into clean solutions</li>
                                            <li>BS/MS degree in Computer Science or equivalent </li>
                                            <li>Experience with working with one or more general purpose languages, at production level: Python, Java, Scala, C/C++.</li>
                                            <li>Experience working with ML/DL libraries like sklearn, Keras, pytorch, tensorflow.</li>
                                            <li>Experience working with large distributed frameworks like Spark.</li>
                                            <li>Demonstrated ability to build, test, deploy ML models into production (please send related github, if possible), into cloud infrastructure.</li>
                                            <li>Experience with constructing/managing ML pipelines and/or data pipelines.</li>
                                            <li>Experience with monitoring of ML models post deployment. Model observability, performance monitoring, alerting and other related MLOps tasks.</li>
                                            <li>Experience working with LLMs like Bert/GPT3.5/Huggingface language models for NLP tasks a plus</li>
                                            <li>Experience with Knowledge Graph a plus</li>
                                            <li>Experience with prompt engineering a plus</li>
                                        </ul>

                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </Container>


                </div>
        )
    }
}
export default Career;