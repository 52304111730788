import React, { useState, useRef, useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
import { Row, Col, Container } from "react-bootstrap";
import { isNil, random } from 'lodash';
import imageCaptureIcon from '../../assets/images/icon-photo-capture.svg';
import retakeIcon from '../../assets/images/icon-photo-retake.svg';
import imageUploadIcon from '../../assets/images/icon-upload-image.svg';
import './UploadImage.scss';
import { storeCameraImage } from '../../common/API';
function CameraCapture() {

    // const WS_URL = 'wss://3.18.172.159:3002';
    const WS_URL = "wss://kjlkkz8ddi.execute-api.us-east-2.amazonaws.com/production";

    const [isCameraOn, setIsCameraOn] = useState(false);
    const [imageData, setImageData] = useState(null);
    const [queryParams, setQueryParams] = useState({})
    const { sendJsonMessage, lastMessage, readyState } = useWebSocket(WS_URL);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const imageRef = useRef(null);
    const streamRef = useRef(null);


    useEffect(() => {
        if (!isNil(window.location.search) && window.location.search !== '') {
            const queryString = window.atob(window.location.search.slice(1));
            const queryParamsArray = queryString.split('&');
            let tempObj = {}
            queryParamsArray.forEach(param => {
                const [key, value] = param.split('=');
                tempObj[key] = (value); // Use decodeURIComponent to handle special characters
            });

            setQueryParams(tempObj)
        }

    }, [window.location.search]);

    useEffect(() => {
        if (!isCameraOn) {
            startCamera();
        }

    }, []);

    const upaloadImage = () => {
        if (!isNil(imageData)) {
            console.log(btoa(imageData))
            let sessionId = random(1000, 500000)
            let params = {
                user_id: queryParams['userId'],
                req_type: 'add',
                session_id: sessionId,
                image_text: imageData
            }
            storeCameraImage(params).then(res => {
               console.log(res);
               alert('Image uploaded successfully.')
            //    sendJsonMessage(
            //     {
            //         action: "broadcastMessage",
            //         message: {
            //             type: 'contentupload',
            //             content: { userId: queryParams['userId'], challengeId: queryParams['challengeId'], boardId: queryParams['boardId'],sessionId: sessionId }
            //         }
            //     }
            // );
            })
           
        }
    }
    const startCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
            videoRef.current.srcObject = stream;
            setIsCameraOn(true);
            streamRef.current = stream;
        } catch (error) {
            console.error('Error accessing the camera:', error);
        }
    };

    const stopCamera = () => {
        // Stop the camera stream when the user is done
        const stream = streamRef.current;
        if (stream) {
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
            setIsCameraOn(false);
        }
    };

    const captureImage = () => {
        if (!isCameraOn) return;

        const video = videoRef.current;
        const canvas = canvasRef.current;
        const image = imageRef.current;

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);

        image.src = canvas.toDataURL('image/jpeg');
        image.style.display = 'block';
        setImageData( canvas.toDataURL('image/jpeg'));
        setIsCameraOn(false);
        stopCamera();
    }
    

    return (
        <div className='position-relative'>
            <div className='cameraHolder'>
                <video
                    ref={videoRef}
                    autoPlay
                    muted
                    style={{ display: isCameraOn ? 'block' : 'none' }}
                ></video>
                <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
                <img
                    ref={imageRef}
                    style={{ display: isCameraOn ? 'none' : 'block', maxWidth: '100%' }}
                    alt="Captured Image"
                />
            </div>
            <div className='cameraBtns'>
                <button style={{ display: isCameraOn ? 'none' : 'block' }} onClick={startCamera}>
                    <img src={retakeIcon} />
                </button>
                <button style={{ display: isCameraOn ? 'block' : 'none' }} onClick={captureImage}>

                    <img src={imageCaptureIcon} />
                </button>
                <button style={{ display: isCameraOn ? 'none' : 'block' }} onClick={upaloadImage}>
                    <img src={imageUploadIcon} />
                </button>
            </div>




        </div>
    );
}

export default CameraCapture;
